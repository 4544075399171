/**
 * @file ResetPasswordPage.js
 * @description パスワード再設定ページのコンポーネント
 */

import React, { useState } from 'react';
import { 
  Typography, 
  Container, 
  Box, 
  TextField, 
  Button, 
  Alert, 
  CircularProgress,
  Link as MuiLink,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { supabase } from '../utils/supabase';

/**
 * パスワード再設定ページ
 * @returns {JSX.Element} パスワード再設定フォームを表示するコンポーネント
 */
const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * パスワードの表示/非表示を切り替える
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * 確認用パスワードの表示/非表示を切り替える
   */
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /**
   * フォーム送信時の処理
   * @param {Event} e - フォームのイベントオブジェクト
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // パスワードの一致確認
    if (password !== confirmPassword) {
      setError('パスワードが一致しません。');
      return;
    }

    setIsLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({
        password: password
      });

      if (error) throw error;

      // パスワード更新成功後、ログインページに遷移
      navigate('/login', { 
        state: { 
          message: 'パスワードが正常に更新されました。新しいパスワードでログインしてください。' 
        } 
      });
    } catch (error) {
      setError(
        error.message === 'New password should be different from the old password'
          ? '新しいパスワードは、以前のパスワードと異なるものを設定してください。'
          : 'パスワードの更新に失敗しました。時間をおいて再度お試しください。'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ 
        mt: 8, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
      }}>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          新しいパスワードの設定
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="新しいパスワード"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="パスワードの表示切り替え"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="新しいパスワード（確認）"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="確認用パスワードの表示切り替え"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{ 
              mt: 3, 
              mb: 2,
              bgcolor: '#0131b4',
              '&:hover': {
                bgcolor: '#1565c0',
              },
              fontSize: '1.1rem',
              height: '50px'
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'パスワードを更新'
            )}
          </Button>

          <Box sx={{ textAlign: 'center' }}>
            <MuiLink component={Link} to="/login" variant="body2">
              ログインページに戻る
            </MuiLink>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage; 