/**
 * @file supabase.js
 * @description Supabaseクライアントの設定と初期化
 */

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// 開発環境とプロダクション環境のドメイン設定
const allowedDomains = [
  'localhost',
  '192.168.10.133',
  'ref-reco-kyo1ms-projects.vercel.app',
  'www.refreco.jp',
  'refreco.jp'
];

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Supabase credentials are missing:', {
    url: !!supabaseUrl,
    key: !!supabaseAnonKey
  });
  throw new Error('Supabase credentials are required');
}

// 現在のドメインがallowedDomainsに含まれているか確認
const currentDomain = window.location.hostname;
const isAllowedDomain = allowedDomains.some(domain => 
  currentDomain === domain || currentDomain.endsWith(`.${domain}`)
);

// Supabaseクライアントの設定
const supabaseOptions = {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    storage: window.localStorage,
    storageKey: 'supabase.auth.token',
    cookieOptions: {
      domain: isAllowedDomain ? currentDomain : 'localhost',
      sameSite: 'Lax',
      secure: window.location.protocol === 'https:',
      path: '/'
    }
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'X-Client-Info': `refreco-web-${currentDomain}`
    }
  },
  realtime: {
    timeout: 30000,
    heartbeat: {
      interval: 15000
    }
  }
};

export const supabase = createClient(supabaseUrl, supabaseAnonKey, supabaseOptions);

// セッションストレージのクリーンアップ
const cleanupStorage = () => {
  try {
    const keys = Object.keys(localStorage);
    const oldTokenKeys = keys.filter(key => 
      key.startsWith('supabase.auth.token') && 
      key !== 'supabase.auth.token'
    );
    
    oldTokenKeys.forEach(key => localStorage.removeItem(key));
  } catch (error) {
    console.error('ストレージクリーンアップエラー:', error);
  }
};

// 初期化時にクリーンアップを実行
cleanupStorage();

let lastEventTimestamp = 0;
const EVENT_DEBOUNCE_MS = 100;

// 認証状態の変更とエラーの監視
supabase.auth.onAuthStateChange((event, session) => {
  const now = Date.now();
  if (now - lastEventTimestamp < EVENT_DEBOUNCE_MS) {
    return;
  }
  lastEventTimestamp = now;

  const timestamp = new Date().toISOString();
  
  // セッション状態の変更をログ
  console.log('Supabase Auth State Change:', {
    event,
    hasSession: !!session,
    timestamp,
    domain: currentDomain,
    sessionDetails: session ? {
      userId: session.user?.id,
      expiresAt: session.expires_at,
    } : null
  });

  // エラーイベントの処理
  if (event === 'TOKEN_REFRESHED') {
    console.log('Token refreshed successfully:', { timestamp });
  } else if (event === 'SIGNED_OUT') {
    console.log('User signed out:', { timestamp });
    localStorage.removeItem('supabase.auth.token');
    cleanupStorage();
  } else if (event === 'USER_DELETED') {
    console.error('User account deleted:', { timestamp });
    cleanupStorage();
  } else if (event === 'USER_UPDATED') {
    console.log('User profile updated:', { timestamp });
  }

  // エラー状態の確認と処理
  if (session?.error) {
    console.error('Supabase Auth Error:', {
      error: session.error,
      errorCode: session.error?.code,
      errorMessage: session.error?.message,
      timestamp,
      domain: currentDomain
    });
    
    if (session.error?.message?.includes('session')) {
      localStorage.removeItem('supabase.auth.token');
      cleanupStorage();
      window.location.reload();
    }
  }
});

// セッション復元エラーのハンドリング
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason?.message?.includes('supabase')) {
    console.error('Unhandled Supabase Error:', {
      error: event.reason,
      domain: currentDomain
    });
    localStorage.removeItem('supabase.auth.token');
    cleanupStorage();
    window.location.reload();
  }
});
