/**
 * @fileoverview 法人向けランディングページコンポーネント
 * @description 企業の人材育成支援ツールとしてのRefRecoを紹介するページ
 */

import React, { useEffect } from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import XIcon from '@mui/icons-material/X';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const LandingPage = () => {

  useEffect(() => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "RefReco",
      "description": "AIを活用した目標管理と振り返りで企業の人材育成を支援するツール",
      "applicationCategory": "BusinessApplication",
      "operatingSystem": "Web",
      "offers": [
        {
          "@type": "Offer",
          "name": "個人プラン",
          "price": "0",
          "priceCurrency": "JPY",
          "availability": "https://schema.org/InStock"
        },
        {
          "@type": "Offer",
          "name": "ビジネスプラン",
          "priceSpecification": {
            "@type": "PriceSpecification",
            "description": "お問い合わせください"
          }
        }
      ],
      "featureList": [
        "AIを活用した目標管理",
        "振り返り支援機能",
        "成長分析機能",
        "OKRフレームワーク対応",
        "1on1支援機能"
      ],
      "url": "https://www.refreco.jp",
      "provider": {
        "@type": "Organization",
        "name": "RefReco",
        "url": "https://www.refreco.jp"
      }
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* ヒーローセクション */}
      <Box sx={{ 
        bgcolor: '#ffffff',
        py: 8,
        mt: { xs: 13.5, sm: 8 }
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* 左側: メインコンテンツ */}
            <Grid item xs={12} md={6}>
              <Typography 
                variant="h1" 
                sx={{ 
                  fontSize: { xs: '2rem', md: '3rem' },
                  fontWeight: 700,
                  color: '#333',
                  mb: 3
                }}
              >
                社員の成長を可視化し<br />
                効率的な人材育成を実現
              </Typography>
              <Typography 
                variant="h2" 
                sx={{ 
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  color: '#666',
                  mb: 4
                }}
              >
                AIを活用した目標管理と振り返りで<br />
                企業の人材育成を支援します
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mb: 4, flexWrap: 'wrap' }}>
                <Button 
                  variant="contained"
                  size="large"
                  href="https://forms.gle/y7sZFHwi8MBhDNNx7"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    bgcolor: '#0131b4',
                    fontSize: '1.1rem',
                    py: 2,
                    px: 4,
                    '&:hover': {
                      bgcolor: '#0043c8'
                    }
                  }}
                >
                  お問い合わせ
                </Button>
                <Button 
                  variant="outlined"
                  size="large"
                  href="https://forms.gle/LLvhM4cCwQk4zjG1A"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    fontSize: '1.1rem',
                    py: 2,
                    px: 4,
                    borderColor: '#0131b4',
                    color: '#0131b4',
                    '&:hover': {
                      borderColor: '#0043c8',
                      bgcolor: 'rgba(1, 49, 180, 0.04)'
                    }
                  }}
                >
                  資料請求
                </Button>
              </Box>
            </Grid>

            {/* 右側: サービスイメージ */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: { xs: '250px', md: '400px' },
                  borderRadius: 2,
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#f8f9fa'
                }}
              >
                <img
                  src="/images/LP/refreco-hero.png"
                  alt="RefRecoのマルチデバイス対応画面"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    maxWidth: '600px'
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* 課題解決セクション */}
      <Box sx={{ bgcolor: '#f8f9fa', py: 10 }} id="challenges">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              mb: 2
            }}
          >
            こんな課題はありませんか？
          </Typography>
          <Typography 
            variant="subtitle1" 
            align="center" 
            color="text.secondary"
            sx={{ mb: 6 }}
          >
            多くの企業が直面している人材育成の課題に、テクノロジーで解決策を提供します
          </Typography>
          <Grid container spacing={4}>
            {challenges.map((challenge, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper 
                  elevation={2} 
                  sx={{ 
                    p: 3,
                    height: '100%',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)'
                    }
                  }}
                >
                  <Typography 
                    variant="h6" 
                    gutterBottom
                    sx={{ 
                      fontWeight: 600,
                      color: '#1a1a1a',
                      mb: 2,
                      textAlign: 'center'
                    }}
                  >
                    {challenge.title}
                  </Typography>
                  <Typography 
                    color="text.secondary" 
                    sx={{ 
                      mb: 3,
                      textAlign: 'center'
                    }}
                  >
                    {challenge.description}
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      height: 200,
                      borderRadius: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={challenge.image}
                      alt={challenge.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* 主要機能紹介セクション */}
      <Box sx={{ py: 10, bgcolor: '#f8f9fa' }} id="features">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              mb: 6
            }}
          >
            主要機能
          </Typography>
          <Grid container spacing={8}>
            {features.map((feature, index) => (
              <Grid item xs={12} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    bgcolor: 'transparent',
                    display: 'flex',
                    flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                    gap: 4,
                    alignItems: 'center',
                    flexWrap: { xs: 'wrap', md: 'nowrap' }
                  }}
                >
                  {/* テキストコンテンツ */}
                  <Box sx={{ flex: 1, minWidth: { xs: '100%', md: '45%' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {feature.icon}
                      <Typography 
                        variant="h4" 
                        component="h3"
                        sx={{ 
                          ml: 2,
                          fontWeight: 600,
                          fontSize: { xs: '1.5rem', md: '2rem' }
                        }}
                      >
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="h6" 
                      color="text.secondary"
                      sx={{ mb: 3 }}
                    >
                      {feature.description}
                    </Typography>
                    <List>
                      {feature.points.map((point, idx) => (
                        <ListItem key={idx} sx={{ px: 0 }}>
                          <ListItemIcon>
                            <CheckCircleOutlineIcon sx={{ color: '#0131b4' }} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={point}
                            primaryTypographyProps={{
                              sx: { fontWeight: 500 }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  {/* 画像コンテナ */}
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: { xs: '100%', md: '45%' },
                      height: { xs: '220px', md: '300px' },
                      bgcolor: '#fff',
                      borderRadius: 2,
                      overflow: 'hidden',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                      transition: 'transform 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)'
                      }
                    }}
                  >
                    <img
                      src={`/images/LP/features/${feature.imageFileName}`}
                      alt={feature.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        padding: '20px'
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* 料金プランセクション */}
      <Box sx={{ bgcolor: '#f8f9fa', py: 10 }} id="pricing">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              mb: 6
            }}
          >
            料金プラン
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {pricingPlans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  elevation={plan.recommended ? 8 : 2}
                  sx={{ 
                    p: 4,
                    height: '100%',
                    position: 'relative',
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-5px)'
                    }
                  }}
                >
                  {plan.recommended && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        bgcolor: '#0131b4',
                        color: 'white',
                        px: 2,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.875rem'
                      }}
                    >
                      おすすめ
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 600 }}>
                      {plan.name}
                    </Typography>
                    <Typography variant="h4" component="p" sx={{ my: 2 }}>
                      ¥{plan.price}
                      <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                        {plan.price === "0" ? "" : "/ユーザー/月"}
                      </Typography>
                    </Typography>
                    <Box sx={{ my: 4 }}>
                      {plan.features.map((feature, idx) => (
                        <Box 
                          key={idx} 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            mb: 1 
                          }}
                        >
                          <CheckCircleOutlineIcon sx={{ mr: 1, color: '#0131b4' }} />
                          <Typography>{feature}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 'auto',
                    pt: 2
                  }}>
                    <Button
                      fullWidth
                      variant={plan.buttonVariant}
                      {...plan.buttonProps}
                      href={plan.buttonProps?.to ? undefined : plan.buttonLink}
                      target={!plan.buttonProps?.to ? "_blank" : undefined}
                      rel={!plan.buttonProps?.to ? "noopener noreferrer" : undefined}
                      sx={{ 
                        mt: 2,
                        bgcolor: '#0131b4',
                        color: 'white',
                        '&:hover': {
                          bgcolor: '#0043c8'
                        }
                      }}
                    >
                      {plan.buttonText}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* 問い合わせセクション */}
      <Box sx={{ bgcolor: '#ffffff', py: 10 }} id="contact">
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 600,
              mb: 6
            }}
          >
            お問い合わせ
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* お問い合わせフォーム */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={2}
                sx={{ 
                  p: 4,
                  height: '100%',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }}
              >
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  導入に関するお問い合わせ
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                  RefRecoの導入についてご検討中の方は、こちらからお問い合わせください。
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  href="https://forms.gle/y7sZFHwi8MBhDNNx7"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    bgcolor: '#0131b4',
                    color: 'white',
                    py: 2,
                    px: 4,
                    '&:hover': {
                      bgcolor: '#0043c8'
                    }
                  }}
                >
                  お問い合わせはこちら
                </Button>
              </Paper>
            </Grid>

            {/* 資料請求フォーム */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={2}
                sx={{ 
                  p: 4,
                  height: '100%',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }}
              >
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                  資料請求
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                  RefRecoの詳細な機能や料金プランについて、資料をご覧になりたい方はこちらから。
                </Typography>
                <Button
                  variant="outlined"
                  size="large"
                  href="https://forms.gle/LLvhM4cCwQk4zjG1A"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    borderColor: '#0131b4',
                    color: '#0131b4',
                    py: 2,
                    px: 4,
                    '&:hover': {
                      borderColor: '#0043c8',
                      bgcolor: 'rgba(1, 49, 180, 0.04)'
                    }
                  }}
                >
                  資料請求はこちら
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* ブッター */}
      <Box sx={{ bgcolor: '#1a1a1a', color: '#fff', py: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                RefReco
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                AIを活用した目標管理と振り返りで企業の人材育成を支援
              </Typography>
              <IconButton
                href="https://x.com/Ref_Reco"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: '#fff' }}
              >
                <XIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box sx={{ borderTop: 1, borderColor: 'rgba(255, 255, 255, 0.1)', mt: 4, pt: 4, textAlign: 'center' }}>
            <Typography variant="body2" color="rgba(255, 255, 255, 0.6)">
              © {new Date().getFullYear()} RefReco. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

// 課題データの更新
const challenges = [
  {
    title: "評価基準が属人的で、公平な評価ができない",
    description: "評価者の主観で評価基準が変わってしまい、不公平感から優秀な人材が離職。",
    image: "/images/LP/challenges/evaluation-bias.png"
  },
  {
    title: "社員一人ひとりのキャリアパスを示さない",
    description: "個々の強みや成長度合いを把握できない。将来のキャリアが見えず、モチベーションが低下。",
    image: "/images/LP/challenges/career-path.png"
  },
  {
    title: "人事データを活用した戦略的な育成がきない",
    description: "日々の評価データや1on1の記録が有効活用できていない。場当たり的な人事施策で成果が出ない。",
    image: "/images/LP/challenges/hr-data.png"
  }
];

// 機能データ
const features = [
  {
    icon: <TrackChangesIcon sx={{ fontSize: 40, color: '#0131b4' }} />,
    title: "目標管理",
    imageFileName: "goal-management.png",
    description: "OKRフレームワークを活用し、組織と個人の目標を連携させます。",
    points: [
      "組織目標と個人目標の紐付け",
      "進捗の可視化とリアルタイム更新",
      "目標達成のためのマイルストーン設定",
      "目標の階層化と関連付け"
    ]
  },
  {
    icon: <TimelineIcon sx={{ fontSize: 40, color: '#0131b4' }} />,
    title: "AI振り返り支援",
    imageFileName: "ai-reflection.png",
    description: "AIが振り返りをサポートし、効果的な改善点を提案します。",
    points: [
      "振り返りの自動析と要約",
      "AIによる改善提案",
      "振り返りテンプレートの提供",
      "定期的な振り返りリマインド"
    ]
  },
  {
    icon: <TrendingUpIcon sx={{ fontSize: 40, color: '#0131b4' }} />,
    title: "成長分析",
    imageFileName: "growth-analysis.png",
    description: "AIを活用して定性・定量データを分析し、多角的な成長を可視化します。",
    points: [
      "AIよる活動内容の自動要約と分析",
      "定性データと定量データを組み合わせた総合評価",
      "自然言語処理による振り返り内容の感情分析",
      "成長傾向の予測と改善提案"
    ]
  }
];

// 料金プランデータ
const pricingPlans = [
  {
    name: "個人プラン",
    price: "0",
    features: [
      "目標管理機能",
      "振り返り機能",
      "成長分析機能",
      "基本的なAI分析",
    ],
    buttonText: "無料で始める",
    buttonLink: "/register",
    buttonVariant: "contained",
    buttonProps: {
      component: Link,
      to: "/register",
    }
  },
  {
    name: "ビジネスプラン",
    price: "応相談",
    recommended: true,
    features: [
      "個人プランの全機能",
      "組織目標との連携",
      "高度なAI分析・提案",
      "カスタムレポート作成",
      "プロジェクト数無制限",
      "専任サポート"
    ],
    buttonText: "お問い合わせ",
    buttonLink: "https://forms.gle/y7sZFHwi8MBhDNNx7",
    buttonVariant: "contained",
    buttonProps: {}
  }
];

export default LandingPage;