/**
 * @fileoverview 振り返りダイアログコンポーネント
 * @module ReflectionDialog
 */

import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReflectionForm from './ReflectionForm';

/**
 * 振り返りダイアログを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 目標オブジェクト
 * @param {Object} [props.reflection] - 編集する振り返りオブジェクト（新規作成時は未定義）
 * @param {Function} props.onClose - ダイアログを閉じる関数
 * @param {Function} props.onSave - 振り返りを保存する関数
 * @param {boolean} props.open - ダイアログの開閉状態
 * @param {string} [props.initialAchievement] - 達成内容の初期値
 * @returns {React.ReactElement} 振り返りダイアログのJSX
 */
const ReflectionDialog = ({ goal, reflection, onClose, onSave, open, initialAchievement }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <ReflectionForm
            goal={goal}
            reflection={reflection}
            onSave={onSave}
            onClose={onClose}
            isDialog={true}
            initialAchievement={initialAchievement}
          />
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ReflectionDialog;
