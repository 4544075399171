/**
 * @file MemberDetailPage.js
 * @description メンバーの詳細情報（ダッシュボード・プロフィール）を表示するページコンポーネント
 */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
  CircularProgress,
  Alert
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import ProfilePage from './ProfilePage';
import { getUserProfile } from '../services/userService';

const MemberDetailPage = () => {
  const { memberId, view } = useParams();
  const navigate = useNavigate();
  const [memberProfile, setMemberProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMemberProfile = async () => {
      try {
        setLoading(true);
        const profile = await getUserProfile(memberId);
        setMemberProfile(profile);
      } catch (err) {
        console.error('メンバー情報の取得に失敗しました:', err);
        setError('メンバー情報の取得に失敗しました。');
      } finally {
        setLoading(false);
      }
    };

    if (memberId) {
      fetchMemberProfile();
    }
  }, [memberId]);

  const handleBack = () => {
    navigate('/admin');
  };

  const handleTabChange = (event, newValue) => {
    navigate(`/admin/members/${memberId}/${newValue}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!memberProfile) {
    return (
      <Box p={3}>
        <Alert severity="error">メンバー情報が見つかりません。</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={handleBack} sx={{ mr: 1 }}>
            <ArrowBack />
          </IconButton>
          <Breadcrumbs aria-label="パンくずリスト">
            <Link 
              component="button"
              variant="body1" 
              onClick={handleBack}
              underline="hover"
            >
              管理画面
            </Link>
            <Typography color="text.primary">
              {memberProfile.display_name || 'メンバー'} の詳細
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={view} 
            onChange={handleTabChange}
            aria-label="メンバー詳細タブ"
          >
            <Tab 
              label="プロフィール" 
              value="profile"
            />
          </Tabs>
        </Box>
      </Paper>

      <Box sx={{ mt: 3 }}>
        {view === 'profile' && (
          <ProfilePage 
            targetUserId={memberId}
            isViewingOther={true}
            userProfile={memberProfile}
          />
        )}
      </Box>
    </Box>
  );
};

export default MemberDetailPage; 