import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Paper, Grid, Button } from '@mui/material';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import { useActivityData } from '../hooks/useActivityData';
import { useTaskData } from '../hooks/useTaskData';
import GoalCard from '../components/goals/GoalCard';
import GoalDialog from '../components/goals/GoalDialog';
import RemoveDialog from '../components/goals/GoalRemoveDialog';
import CompleteDialog from '../components/goals/GoalCompleteDialog';
import RevertDialog from '../components/goals/GoalRevertDialog';
import ReflectionDialog from '../components/reflections/ReflectionDialog';
import ActivityHistory from '../components/activities/ActivityHistory';
import ReflectionTimeSeriesChart from '../components/charts/ReflectionTimeSeriesChart';
import ReflectionSummaryCard from '../components/summary/ReflectionSummaryCard';
import ReflectionSkillPieChart from '../components/charts/ReflectionSkillPieChart';
import AdComponent from '../components/common/AdComponent';
import AchievementProgressChart from '../components/charts/AchievementProgressChart';
import ReflectionRemoveDialog from '../components/reflections/ReflectionRemoveDialog';
import ReflectionPage from './ReflectionPage';
import RecentReflections from '../components/reflections/RecentReflections';
import TaskList from '../components/tasks/TaskList';
import ReflectionSummaryGenerator from '../components/summary/ReflectionSummaryGenerator';
import GoalSupportBubble from '../components/goals/GoalSupportBubble';
import { openaiService } from '../services/openaiService';
import GoalDetailChatBox from '../components/goals/GoalDetailChatBox';

const GoalDetailPage = ({ id, onClose, onUpdate }) => {
  const navigate = useNavigate();
  const [selectedGoalForReflection, setSelectedGoalForReflection] = useState(null);
  const [defaultAchievement, setDefaultAchievement] = useState('');
  const [refreshTasksCounter, setRefreshTasksCounter] = useState(0);
  const { 
    goals, 
    loading: goalsLoading, 
    error: goalsError, 
    fetchGoals,
    handleEditGoal,
    handleDeleteGoal,
    handleStatusChange
  } = useGoalData(null, id);

  const goal = goals[0];

  const { goals: allGoals } = useGoalData();

  const {
    reflections,
    loading: reflectionsLoading,
    error: reflectionsError,
    handleEditReflection,
    handleDeleteReflection,
    fetchReflections
  } = useReflectionData(id);

  const {
    activities,
    loading: activitiesLoading,
    error: activitiesError,
    fetchActivities
  } = useActivityData(id);

  const {
    handleAddTask,
  } = useTaskData(id);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [showReflectionEditModal, setShowReflectionEditModal] = useState(false);
  const [showReflectionRemoveModal, setShowReflectionRemoveModal] = useState(false);
  const [selectedReflection, setSelectedReflection] = useState(null);
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [isReflectionPageOpen, setIsReflectionPageOpen] = useState(false);
  const [selectedReflectionId, setSelectedReflectionId] = useState(null);
  const [refreshReflections, setRefreshReflections] = useState(false);
  const [supportMessage, setSupportMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [prevGoal, setPrevGoal] = useState(null);
  const [prevReflections, setPrevReflections] = useState([]);

  // 更新が必要かどうかを判断するヘルパー関数
  const shouldUpdateMessage = (prevGoal, newGoal, prevReflections, newReflections) => {
    if (!prevGoal || !newGoal) return true;
    
    return (
      prevGoal.status !== newGoal.status ||
      prevGoal.is_completed !== newGoal.is_completed ||
      prevGoal.actual_value !== newGoal.actual_value ||
      prevReflections.length !== newReflections.length ||
      // 最新の振り返りの内容が変更された場合
      (newReflections[0] && prevReflections[0] && 
       (newReflections[0].id !== prevReflections[0].id ||
        newReflections[0].updated_at !== prevReflections[0].updated_at))
    );
  };

  // AIサポートメッセージを生成する関数
  const generateSupportMessage = async () => {
    if (!goal) return;

    // 更新が必要かどうかを判断
    if (!shouldUpdateMessage(prevGoal, goal, prevReflections, reflections)) {
      return;
    }

    try {
      const message = await openaiService.generateGoalSupportMessage({
        goal: {
          id: goal.id,
          title: goal.title,
          description: goal.description,
          deadline: goal.deadline,
          is_completed: goal.is_completed,
          target_value: goal.target_value,
          actual_value: goal.actual_value,
          status: goal.status
        },
        reflections
      });
      setSupportMessage(message);

      // 現在の状態を保存
      setPrevGoal(goal);
      setPrevReflections(reflections);
    } catch (error) {
      console.error('サポートメッセージの生成に失敗しました:', error);
      setSupportMessage('がんばっていますね！一緒に目標達成を目指しましょう！');
    } finally {
      setLoadingMessage(false);
    }
  };

  // 目標データが取得されたらサポートメッセージを生成
  useEffect(() => {
    if (goal && reflections) {
      generateSupportMessage();
    }
  }, [
    goal?.status,
    goal?.is_completed,
    goal?.actual_value,
    reflections.length,
    // 最新の振り返りの更新日時
    reflections[0]?.updated_at
  ]);

  const handleEditClick = () => setShowEditModal(true);
  const handleRemoveClick = () => setShowRemoveModal(true);
  const handleCompleteClick = () => setShowCompleteModal(true);
  const handleRevertClick = () => setShowRevertModal(true);
  const handleReflectionClick = (goal, reflectionId = null) => {
    setSelectedGoalForReflection(goal);
    setSelectedReflectionId(reflectionId);
    setIsReflectionPageOpen(true);
  };
  const handleEditReflectionClick = (reflection) => {
    setSelectedReflection(reflection);
    setShowReflectionEditModal(true);
  };
  const handleDeleteReflectionsClick = (reflectionIds) => {
    setSelectedReflections(reflectionIds);
    setShowReflectionRemoveModal(true);
  };
  const handleDeleteReflections = async () => {
    for (const reflectionId of selectedReflections) {
      await handleDeleteReflection({ id: reflectionId });
    }
    setShowReflectionRemoveModal(false);
    handleActivityChange();
  };

  const handleDeleteGoalAndNavigate = async () => {
    await handleDeleteGoal(goal);
    navigate('/');
  };

  const handleUpdateData = async () => {
    await Promise.all([
      fetchGoals(),
      fetchActivities(),
      fetchReflections()
    ]);
    setShowEditModal(false);
    setShowReflectionModal(false);
    setShowReflectionEditModal(false);
    if (onUpdate) {
      await onUpdate();
    }
  };

  const handleActivityChange = () => {
    fetchGoals();
    fetchActivities();
  };

  const handleCloseReflectionPage = () => {
    setIsReflectionPageOpen(false);
    setSelectedReflectionId(null);
    handleActivityChange();
  };

  const handleReflectionUpdate = () => {
    fetchReflections();
    handleActivityChange();
    setRefreshReflections(prev => !prev);
  };

  const handleTaskComplete = (taskContent) => {
    setDefaultAchievement(taskContent);
    setShowReflectionModal(true);
  };

  const handleTasksGenerated = async (tasks) => {
    // 生成された各タスクを追加
    for (const task of tasks) {
      await handleAddTask(task.content);
    }
    // タスクリストの更新をトリガー
    setRefreshTasksCounter(prev => prev + 1);
  };

  if (goalsLoading || reflectionsLoading) return <Typography>読み込み中...</Typography>;
  if (goalsError) return <Typography color="error">エラー: {goalsError}</Typography>;
  if (reflectionsError) return <Typography color="error">エラー: {reflectionsError}</Typography>;
  if (!goals[0]) return <Typography>目標が見つかりません。</Typography>;

  const availableParentGoals = allGoals.filter(g => g.id !== goal.id);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="body1"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          ← ダッシュボードに戻る
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
            <GoalDetailChatBox 
              goal={goal}
              reflections={reflections}
              onTasksGenerated={handleTasksGenerated}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <GoalCard
              goal={goal}
              isCompleted={goal.is_completed}
              onReflectionClick={() => handleReflectionClick(goal)}
              onEditClick={handleEditClick}
              onRemoveClick={handleRemoveClick}
              onCompleteClick={handleCompleteClick}
              onRevertClick={handleRevertClick}
              onStatusChange={handleStatusChange}
              hideDetailLink={true} 
              showFullDetails={true}
              childGoals={goals.filter(g => g.parent_goal_id === goal.id)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <TaskList 
            goalId={goal.id} 
            onTaskComplete={handleTaskComplete}
            refreshTasks={refreshTasksCounter}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionSummaryCard 
              goalID={goal.id} 
              showDetails={false}
              onEditReflection={handleEditReflectionClick}
              onDeleteReflections={handleDeleteReflectionsClick}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionSkillPieChart goalID={goal.id} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <ReflectionSummaryGenerator 
            goalId={goal.id} 
            reflections={reflections} 
          />
        </Grid>

        <Grid item xs={12}>
          <RecentReflections 
            reflections={reflections} 
            defaultProjectFilter={goal.project_id}
            defaultGoalFilter={goal.id}
            refreshTrigger={refreshReflections}
          />
        </Grid>

        {reflections.some(reflection => reflection.actual_value !== null) && (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <AchievementProgressChart goalID={goal.id} />
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <ReflectionTimeSeriesChart goalID={goal.id} />
          </Paper>
        </Grid>

        {/* <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, maxHeight: '75vh', overflowY: 'auto' }}>
            <ActivityHistory
              activities={activities}
              goalId={goal.id} 
              onEditGoal={handleEditGoal}
              onDeleteGoal={handleDeleteGoal}
              onEditReflection={handleEditReflection}
              onDeleteReflection={handleDeleteReflection}
              onActivityChange={handleActivityChange} 
            />
          </Paper>
        </Grid> */}
      </Grid>

      <Box sx={{ my: 4 }}>
        <AdComponent />
      </Box>

      {showEditModal && (
        <GoalDialog
          goal={goal}
          onClose={() => setShowEditModal(false)}
          onSave={async (updatedGoal) => {
            try {
              await handleEditGoal(updatedGoal);
              await handleUpdateData();
            } catch (error) {
              console.error('目標の更新に失敗しました:', error);
            }
          }}
          availableParentGoals={availableParentGoals}
        />
      )}
      {showRemoveModal && (
        <RemoveDialog
          goal={goal}
          onClose={() => setShowRemoveModal(false)}
          onRemove={handleDeleteGoalAndNavigate}
        />
      )}
      {showCompleteModal && (
        <CompleteDialog
          goal={goal}
          onClose={() => setShowCompleteModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showRevertModal && (
        <RevertDialog
          goal={goal}
          onClose={() => setShowRevertModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showReflectionModal && (
        <ReflectionDialog
          goal={goal}
          onClose={() => {
            setShowReflectionModal(false);
            setDefaultAchievement('');
          }}
          onSave={handleReflectionUpdate}
          open={showReflectionModal}
          onActivityChange={handleActivityChange}
          initialAchievement={defaultAchievement}
        />
      )}
      {showReflectionEditModal && (
        <ReflectionDialog
          goal={goal}
          reflection={selectedReflection}
          onClose={() => {
            setShowReflectionEditModal(false);
            setSelectedReflection(null);
          }}
          onSave={(updatedReflection) => {
            handleEditReflection(updatedReflection);
            handleReflectionUpdate();
          }}
          open={showReflectionEditModal}
          onActivityChange={handleActivityChange}
        />
      )}
      {showReflectionRemoveModal && (
        <ReflectionRemoveDialog
          reflections={selectedReflections}
          onClose={() => setShowReflectionRemoveModal(false)}
          onRemove={handleDeleteReflections}
          onActivityChange={handleActivityChange}
        />
      )}
      <ReflectionPage
        isOpen={isReflectionPageOpen}
        onClose={handleCloseReflectionPage}
        goalId={selectedGoalForReflection?.id}
        reflectionId={selectedReflectionId}
      />
    </Box>
  );
};

export default GoalDetailPage;
