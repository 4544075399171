import React from 'react';
import { profileSummaryService } from '../../services/summaryService';
import { openaiService } from '../../services/openaiService';
import SummaryGenerator from '../common/SummaryGenerator';

/**
 * プロフィール要約生成コンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.goals - 目標データ
 * @param {Array} props.completedGoals - 完了済み目標データ
 * @param {Array} props.reflections - 振り返りデータ
 * @param {Object} props.accumulatedValues - 累積値データ
 * @param {string} [props.targetUserId] - 表示対象のユーザーID
 * @param {boolean} [props.isViewingOther] - 他のユーザーのプロフィールを表示しているかどうか
 */
const ProfileSummaryGenerator = ({ 
  goals, 
  completedGoals, 
  reflections, 
  accumulatedValues,
  targetUserId,
  isViewingOther = false
}) => {
  const generateSummary = async (model, userInstructions) => {
    return await openaiService.generateProfileSummary(
      { goals, completedGoals, reflections, accumulatedValues },
      model,
      userInstructions,
      targetUserId
    );
  };

  return (
    <SummaryGenerator
      title={isViewingOther ? "メンバープロフィール要約" : "プロフィール要約"}
      summaryService={profileSummaryService}
      targetId={targetUserId}
      generateSummary={generateSummary}
      isViewingOther={isViewingOther}
      useTargetIdAsUserId={true}
    />
  );
};

export default ProfileSummaryGenerator;
