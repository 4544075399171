/**
 * @fileoverview 振り返りスキルの割合を円グラフで表示するコンポーネント
 * @module ReflectionSkillPieChart
 */

import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { useReflectionData } from '../../hooks/useReflectionData';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57'];

/**
 * 振り返りスキルの割合を円グラフで表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string|null} props.goalID - 目標ID（オプション）
 * @param {string|null} props.projectID - プロジェクトID（オプション）
 * @param {string|null} props.targetUserId - 表示対象のユーザーID（オプション）
 * @param {boolean} props.isViewingOther - 他のユーザーのデータを表示しているかどうか
 * @returns {JSX.Element} ReflectionSkillPieChartコンポーネント
 */
const ReflectionSkillPieChart = ({ 
  goalID = null, 
  projectID = null, 
  targetUserId = null,
  isViewingOther = false
}) => {
  const [metricType, setMetricType] = useState('count');
  const { reflections, loading, error } = useReflectionData(goalID, projectID, targetUserId);
  const [skillData, setSkillData] = useState([]);

  useEffect(() => {
    if (reflections.length > 0) {
      processSkillData();
    }
  }, [reflections, metricType]);

  /**
   * スキルデータを処理する
   */
  const processSkillData = () => {
    const skillCounts = { 'スキルなし': 0 };
    const skillDurations = { 'スキルなし': 0 };

    reflections.forEach(reflection => {
      if (!Array.isArray(reflection.skills) || reflection.skills.length === 0) {
        // スキルが未設定の場合
        skillCounts['スキルなし']++;
        skillDurations['スキルなし'] += reflection.reflection_duration;
      } else {
        const skillCount = reflection.skills.length;
        reflection.skills.forEach(skill => {
          if (!skillCounts[skill.name]) {
            skillCounts[skill.name] = 0;
            skillDurations[skill.name] = 0;
          }
          skillCounts[skill.name]++;
          // 各スキルに振り返り時間を均等に分配
          skillDurations[skill.name] += reflection.reflection_duration / skillCount;
        });
      }
    });

    const processedData = Object.keys(skillCounts).map(skill => ({
      name: skill,
      value: metricType === 'count' ? skillCounts[skill] : Math.round(skillDurations[skill])
    }));

    setSkillData(processedData);
  };

  /**
   * メトリクスタイプの変更を処理する
   * @param {Event} event - イベントオブジェクト
   * @param {string} newMetricType - 新しいメトリクスタイプ
   */
  const handleMetricTypeChange = (event, newMetricType) => {
    if (newMetricType !== null) {
      setMetricType(newMetricType);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${data.name}: ${data.value}${metricType === 'count' ? '回' : '分'}`}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) return <Typography>読み込み中...</Typography>;
  if (error) return <Typography color="error">エラー: {error}</Typography>;

  return (
    <Box sx={{ height: 300, width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">スキル別振り返り割合</Typography>
        <Tabs
          value={metricType}
          onChange={handleMetricTypeChange}
          aria-label="メトリクス選択"
          sx={{ minHeight: 0 }}
        >
          <Tab label="回数" value="count" sx={{ minHeight: 0, py: 1 }} />
          <Tab label="時間" value="duration" sx={{ minHeight: 0, py: 1 }} />
        </Tabs>
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={skillData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {skillData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend 
            layout="vertical" 
            align="right" 
            verticalAlign="middle"
            wrapperStyle={{
              paddingLeft: '20px',
              maxHeight: '200px',
              overflowY: 'auto'
            }}
          />
        </PieChart>
      </ResponsiveContainer>
      {metricType === 'duration' && (
        <Typography variant="caption" sx={{ mt: 1 }}>
          ※ スキル別の時間は、各振り返りの時間を登録されたスキル数で均等に分配しています。
        </Typography>
      )}
    </Box>
  );
};

export default ReflectionSkillPieChart;
