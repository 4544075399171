import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Autocomplete, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, Paper, Tooltip, Stepper, Step, StepLabel, StepContent, Card, CardContent, Collapse, Alert, Chip, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns'; // parseISO関数をインポート
import ja from 'date-fns/locale/ja'; // 日本語ロケールをインポート
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleAddLink, handleLinkChange, handleRemoveLink } from '../../utils/commonUtils';
import { goalService } from '../../services/goalService';
import { unitService } from '../../services/unitService';
import { supabase } from '../../utils/supabase';
import { useProjectData } from '../../hooks/useProjectData';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';

/**
 * 目標ダイアログコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object|null} props.goal - 編集する目標オブジェクト（新規作成時はnull）
 * @param {Object|null} props.initialData - 目標の初期データ（新規作成時はnull）
 * @param {Array} props.subGoals - サブ目標のリスト（新規作成時は空）
 * @param {Function} props.onClose - ダイアログを閉じる際のコールバック
 * @param {Function} props.onSave - 目標保存時のコールバック
 * @param {Array} props.availableParentGoals - 選択可能な親目標のリスト
 * @param {Object|null} props.parentGoal - 親目標オブジェクト（新規作成時はnull）
 * @param {string|null} props.defaultProjectId - プロジェクトIDの初期値（新規作成時はnull）
 * @param {string|null} props.defaultParentGoalId - 親目標IDの初期値（新規作成時はnull）
 * @returns {React.Component} 目標ダイアログコンポーネント
 */
const GoalDialog = ({ 
  goal = null, 
  initialData = null, 
  subGoals = [], 
  onClose, 
  onSave, 
  availableParentGoals = [], 
  parentGoal = null,
  defaultProjectId = null,
  defaultParentGoalId = null
}) => {
  const [title, setTitle] = useState(initialData?.title || goal?.title || '');
  const [description, setDescription] = useState(initialData?.description || goal?.description || '');
  const [deadline, setDeadline] = useState(goal && goal.deadline ? parseISO(goal.deadline) : null);
  const [targetValue, setTargetValue] = useState(goal ? goal.target_value || '' : '');
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [customUnit, setCustomUnit] = useState('');
  const [referenceLinks, setReferenceLinks] = useState(goal && goal.reference_links ? goal.reference_links : [{ url: '', title: '' }]);
  const [status, setStatus] = useState(goal ? goal.status || 'not_started' : 'not_started');
  const [completeDate, setCompleteDate] = useState(goal && goal.complete_date ? parseISO(goal.complete_date) : null);
  const [projectId, setProjectId] = useState(
    goal ? goal.project_id : (defaultProjectId || '')
  );
  const [parentGoalId, setParentGoalId] = useState(
    goal ? goal.parent_goal_id : (defaultParentGoalId || '')
  );
  const { projects } = useProjectData();
  const [activeProjects, setActiveProjects] = useState([]);
  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();
  const [subGoalsList, setSubGoalsList] = useState(subGoals);
  const [editingSubGoalIndex, setEditingSubGoalIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showParentGoalDetails, setShowParentGoalDetails] = useState(false);

  useEffect(() => {
    fetchUnits();
    if (goal) {
      setProjectId(goal.project_id || '');
    }
    // アクティブなプロジェクトのみをフィルタリング
    setActiveProjects(projects.filter(project => !project.is_completed));
  }, [goal, projects]);

  const fetchUnits = async () => {
    try {
      const userId = await getUserId();
      const data = await unitService.fetchUnits(userId);
      setUnits(data);
      if (goal && goal.unit_id) {
        const currentUnit = data.find(unit => unit.id === goal.unit_id);
        setSelectedUnit(currentUnit || null);
      }
    } catch (error) {
      console.error('単位の取得に失敗しました:', error);
    }
  };

  const getUserId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('ユーザーが見つかりません');
    return user.id;
  };

  const handleUnitChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setCustomUnit(newValue);
      setSelectedUnit(null);
    } else if (newValue && newValue.inputValue) {
      setCustomUnit(newValue.inputValue);
      setSelectedUnit(null);
    } else {
      setSelectedUnit(newValue);
      setCustomUnit('');
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) errors.title = '目標名は必須です';
    if (targetValue && isNaN(parseFloat(targetValue))) errors.targetValue = '有効な数値を入力してください';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      const userId = await getUserId();
      const utcDeadline = deadline 
        ? new Date(Date.UTC(deadline.getFullYear(), deadline.getMonth(), deadline.getDate()))
        : null;
      const utcCompleteDate = completeDate
        ? new Date(Date.UTC(completeDate.getFullYear(), completeDate.getMonth(), completeDate.getDate()))
        : null;

      // 数値と単位の処理を改善
      let unitId = null;
      if (selectedUnit) {
        unitId = selectedUnit.id;
      } else if (customUnit) {
        const newUnit = await unitService.createUnit(customUnit, userId);
        unitId = newUnit.id;
      }

      // 数値の変換を改善
      const parsedTargetValue = targetValue ? parseFloat(targetValue) : null;
      if (targetValue && isNaN(parsedTargetValue)) {
        throw new Error('目標数値が正しくありません');
      }

      const goalData = { 
        title, 
        description, 
        deadline: utcDeadline ? utcDeadline.toISOString() : null,
        target_value: parsedTargetValue,
        unit_id: unitId,
        reference_links: referenceLinks.filter(link => link.url),
        status: status,
        complete_date: status === 'completed' ? utcCompleteDate?.toISOString() : null,
        is_completed: status === 'completed',
        project_id: projectId || null,
        parent_goal_id: parentGoalId || null,
        user_id: userId
      };

      // サブ目標のデータを整形
      const formattedSubGoals = subGoalsList.map(subGoal => ({
        title: subGoal.title,
        description: subGoal.description,
        deadline: subGoal.deadline,
        target_value: subGoal.target_value ? parseFloat(subGoal.target_value) : null,
        unit_id: subGoal.unit_id,
        status: 'not_started',
        is_completed: false,
        reference_links: [],
        project_id: projectId || null,
        user_id: userId
      }));

      // メイン目標とサブ目標を一括で保存
      const result = await goalService.createGoalWithSubGoals(
        goal ? { ...goalData, id: goal.id } : goalData,
        formattedSubGoals
      );

      await onSave(result);
      onClose();
    } catch (error) {
      console.error('目標の保存中にエラーが発生しました:', error);
      // エラーメッセージをユーザーに表示
      alert(error.message || '目標の保存中にエラーが発生しました');
    }
  };

  const handleSubGoalEdit = (index) => {
    setEditingSubGoalIndex(index);
  };

  const handleSubGoalSave = (index, updatedSubGoal) => {
    const newSubGoals = [...subGoalsList];
    newSubGoals[index] = updatedSubGoal;
    setSubGoalsList(newSubGoals);
    setEditingSubGoalIndex(null);
  };

  const handleSubGoalDelete = (index) => {
    const newSubGoals = subGoalsList.filter((_, i) => i !== index);
    setSubGoalsList(newSubGoals);
  };

  const handleAddSubGoal = () => {
    const newSubGoal = { 
      title: '', 
      description: '', 
      deadline: null, 
      target_value: '', 
      unit_id: null 
    };
    setSubGoalsList([...subGoalsList, newSubGoal]);
    setEditingSubGoalIndex(subGoalsList.length);
  };

  // 親目標カードのレンダリング
  const renderParentGoalCard = () => {
    if (!parentGoalId) return null;
    const selectedParentGoal = availableParentGoals.find(g => g.id === parentGoalId);
    if (!selectedParentGoal) return null;

    return (
      <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" component="div">
              親目標: {selectedParentGoal.title}
            </Typography>
            <IconButton
              onClick={() => setShowParentGoalDetails(!showParentGoalDetails)}
              aria-expanded={showParentGoalDetails}
            >
              {showParentGoalDetails ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          <Collapse in={showParentGoalDetails}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {selectedParentGoal.description}
              </Typography>
              {selectedParentGoal.target_value && (
                <Chip
                  label={`目標値: ${selectedParentGoal.target_value} ${selectedParentGoal.unit_name || ''}`}
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {goal ? '目標の編集' : '新しい目標'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {/* 基本情報セクション */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              基本情報
              <Tooltip title="目標の基本的な情報を入力してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>プロジェクト</InputLabel>
                  <Select
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                    label="プロジェクト"
                    size="small"
                    error={!!formErrors.projectId}
                  >
                    <MenuItem value=""><em>なし</em></MenuItem>
                    {activeProjects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>親目標</InputLabel>
                  <Select
                    value={parentGoalId}
                    onChange={(e) => setParentGoalId(e.target.value)}
                    label="親目標"
                    size="small"
                    error={!!formErrors.parentGoalId}
                  >
                    <MenuItem value=""><em>なし</em></MenuItem>
                    {availableParentGoals.map((parentGoal) => (
                      <MenuItem key={parentGoal.id} value={parentGoal.id}>
                        {parentGoal.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {renderParentGoalCard()}

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="目標名"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  size="small"
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DatePicker
                    label="期限"
                    value={deadline}
                    onChange={(newValue) => setDeadline(newValue)}
                    format="yyyy/MM/dd"
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        size: 'small',
                        error: !!formErrors.deadline,
                        helperText: formErrors.deadline
                      } 
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>状態</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="状態"
                    size="small"
                  >
                    <MenuItem value="not_started">未着手</MenuItem>
                    <MenuItem value="in_progress">進行中</MenuItem>
                    <MenuItem value="completed">完了</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {status === 'completed' && (
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                    <DatePicker
                      label="完了日"
                      value={completeDate}
                      onChange={(newValue) => setCompleteDate(newValue)}
                      format="yyyy/MM/dd"
                      slotProps={{ 
                        textField: { 
                          fullWidth: true,
                          size: 'small'
                        } 
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </Paper>

          {/* 目標詳細セクション */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              目標詳細
              <Tooltip title="目標の詳細な内容と数値目標を設定してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Box sx={mdxStyles}>
              <MDXEditor
                markdown={description}
                onChange={(value) => setDescription(value)}
                plugins={mdxPlugins}
                contentEditableClassName="prose max-w-full"
                style={mdxStyles}
              />
            </Box>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="目標数値"
                  type="number"
                  value={targetValue}
                  onChange={(e) => setTargetValue(e.target.value)}
                  size="small"
                  fullWidth
                  error={!!formErrors.targetValue}
                  helperText={formErrors.targetValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={units}
                  getOptionLabel={(option) => option.name}
                  value={selectedUnit}
                  onChange={handleUnitChange}
                  onInputChange={(event, newInputValue) => {
                    setCustomUnit(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label="単位" 
                      placeholder='選択または入力' 
                      size="small"
                      error={!!formErrors.unit}
                      helperText={formErrors.unit}
                    />
                  )}
                  freeSolo
                />
              </Grid>
            </Grid>
          </Paper>

          {/* 参考情報セクション */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              参考情報
              <Tooltip title="目標達成に参考となる資料やWebサイトのURLを追加してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            {referenceLinks.map((link, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="URL"
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_url`]}
                  helperText={formErrors[`link_${index}_url`]}
                />
                <TextField
                  label="タイトル"
                  value={link.title}
                  onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
                  size="small"
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_title`]}
                  helperText={formErrors[`link_${index}_title`]}
                />
                <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<LinkIcon />}
              onClick={() => handleAddLink(setReferenceLinks)}
              variant="outlined"
              size="small"
              fullWidth
            >
              リンクを追加
            </Button>
          </Paper>

          {/* サブ目標セクション */}
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              サブ目標
              <Tooltip title="必要に応じてサブ目標を設定してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            {subGoalsList.map((subGoal, index) => (
              <Box key={index} sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                {editingSubGoalIndex === index ? (
                  <SubGoalEditForm
                    subGoal={subGoal}
                    onSave={(updatedSubGoal) => handleSubGoalSave(index, updatedSubGoal)}
                    onCancel={() => setEditingSubGoalIndex(null)}
                    units={units}
                  />
                ) : (
                  <Card variant="outlined" sx={{ width: '100%', mb: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">{subGoal.title}</Typography>
                        <Box>
                          <IconButton onClick={() => handleSubGoalEdit(index)} size="small">
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleSubGoalDelete(index)} size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {subGoal.description && (
                        <Typography variant="body2" color="text.secondary">
                          {subGoal.description}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Box>
            ))}
            <Button
              onClick={handleAddSubGoal}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{ mt: 2 }}
            >
              サブ目標を追加
            </Button>
          </Paper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SubGoalEditForm = ({ subGoal, onSave, onCancel, units }) => {
  const [title, setTitle] = useState(subGoal.title);
  const [description, setDescription] = useState(subGoal.description);
  const [deadline, setDeadline] = useState(subGoal.deadline ? new Date(subGoal.deadline) : null);
  const [targetValue, setTargetValue] = useState(subGoal.target_value || '');
  const [selectedUnit, setSelectedUnit] = useState(units.find(unit => unit.id === subGoal.unit_id) || null);

  const handleSave = () => {
    onSave({
      title,
      description,
      deadline: deadline ? deadline.toISOString() : null,
      target_value: targetValue ? parseFloat(targetValue) : null,
      unit_id: selectedUnit ? selectedUnit.id : null,
      status: 'not_started',  // デフォルトのステータスを設定
      reference_links: [],    // 空の参照リンク配列を設定
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <TextField
        label="サブ目標タイトル"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <TextField
        label="説明"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={2}
        fullWidth
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <DatePicker
          label="期限"
          value={deadline}
          onChange={(newValue) => setDeadline(newValue)}
          format="yyyy/MM/dd"
          slotProps={{ 
            textField: { 
              fullWidth: true,
              size: 'small'
            } 
          }}
        />
      </LocalizationProvider>
      <TextField
        label="目標数値"
        type="number"
        value={targetValue}
        onChange={(e) => setTargetValue(e.target.value)}
        fullWidth
      />
      <Autocomplete
        options={units}
        getOptionLabel={(option) => option.name}
        value={selectedUnit}
        onChange={(event, newValue) => {
          setSelectedUnit(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="単位" />}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button onClick={onCancel}>キャンセル</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default GoalDialog;
