/**
 * @fileoverview マークダウンレンダリング用の共通コンポーネント
 * 
 * このコンポーネントは、マークダウンテキストを適切な行間とスタイリングで表示します。
 * 
 * @requires React
 * @requires react-markdown
 * @requires @mui/material
 */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography, Box } from '@mui/material';

/**
 * マークダウンレンダリングコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.children - マークダウンテキスト
 * @returns {React.Component} マークダウンレンダリングコンポーネント
 */
const MarkdownRenderer = ({ children }) => {
  return (
    <Box sx={{ color: 'text.secondary' }}>
      <ReactMarkdown
        components={{
          p: ({node, ...props}) => (
            <Typography 
              variant="body2" 
              {...props} 
              sx={{ 
                my: 0.5,
                lineHeight: 1.4
              }} 
            />
          ),
          li: ({node, ...props}) => (
            <li 
              {...props} 
              style={{ 
                marginTop: '2px', 
                marginBottom: '2px',
                lineHeight: 1.4
              }} 
            />
          ),
          ul: ({node, ...props}) => (
            <ul 
              {...props} 
              style={{ 
                marginTop: '4px', 
                marginBottom: '4px',
                paddingLeft: '20px'
              }} 
            />
          ),
          ol: ({node, ...props}) => (
            <ol 
              {...props} 
              style={{ 
                marginTop: '4px', 
                marginBottom: '4px',
                paddingLeft: '20px'
              }} 
            />
          )
        }}
      >
        {children}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownRenderer; 