/**
 * @fileoverview 目標サービス
 * @description 目標の取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const goalService = {
  /**
   * ユーザーの目標を取得する
   * @param {string} userId - ユーザーID
   * @param {boolean|null} isCompleted - 完了状態 (オプション)
   * @param {string|null} goalId - 目標ID (オプション)
   * @param {string|null} projectId - プロジェクトID (オプション)
   * @returns {Promise<Array>} 目標の配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchGoals(userId, isCompleted = null, goalId = null, projectId = null) {
    let query = supabase
      .from('goals')
      .select(`
        id,
        created_at,
        user_id,
        title,
        description,
        deadline,
        is_completed,
        complete_date,
        target_value,
        unit_id,
        updated_at,
        reference_links,
        status,
        project_id,
        parent_goal_id,
        units:unit_id(name),
        reflections:reflections(id, reflection_duration, actual_value),
        parent_goal:parent_goal_id(id, title),
        child_goals:goals!parent_goal_id(id, title)
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (isCompleted !== null) {
      query = query.eq('is_completed', isCompleted);
    }

    if (goalId) {
      query = query.eq('id', goalId);
    }

    if (projectId && projectId !== 'all') {
      query = query.eq('project_id', projectId);
    }

    const { data, error } = await query;

    if (error) {
      console.error('目標の取得に失敗しました:', error);
      throw error;
    }

    return this.processGoals(data);
  },

  /**
   * 新しい目標を作成する
   * @param {Object} goalData - 目標データ
   * @returns {Promise<Object>} 作成された目標
   * @throws {Error} 作成に失敗した場合
   */
  async createGoal(goalData) {
    try {
      const { data, error } = await supabase
        .from('goals')
        .insert([{ ...goalData, is_completed: false }]) // デフォルト値を設定
        .select()
        .single();

      if (error) {
        console.error('目標の作成に失敗しました:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('目標の作成に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 目標を更新する
   * @param {string} goalId - 目標ID
   * @param {Object} updatedGoal - 更新する目標データ
   * @returns {Promise<Object>} 更新された目標
   * @throws {Error} 更新に失敗した場合
   */
  async updateGoal(goalId, updatedGoal) {
    const { data, error } = await supabase
      .from('goals')
      .update(updatedGoal)
      .eq('id', goalId)
      .select();

    if (error) {
      console.error('目標の更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 目標を削除する
   * @param {string} goalId - 目標ID
   * @throws {Error} 削除に失敗した場合
   */
  async deleteGoal(goalId) {
    const { error } = await supabase
      .from('goals')
      .delete()
      .eq('id', goalId);

    if (error) {
      console.error('目標の削除に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 目標を元に戻す
   * @param {string} goalId - 目標ID
   * @returns {Promise<Object>} 元に戻された目標
   * @throws {Error} 元に戻しに失敗した場合
   */
  async revertGoal(goalId) {
    const { data, error } = await supabase
      .from('goals')
      .update({ 
        is_completed: false,
        complete_date: null,
        status: 'in_progress' // 元に戻す際はin_progressに設定
      })
      .eq('id', goalId)
      .select();
  
    if (error) {
      console.error('目標の元に戻しに失敗しました:', error);
      throw error;
    }
  
    return data[0];
  },

  /**
   * 目標データを処理する
   * @param {Array} goals - 目標の配列
   * @returns {Array} 処理された目標の配列
   */
  processGoals(goals) {
    return goals.map(goal => {
      const totalActualValue = goal.reflections.reduce((sum, reflection) => {
        const actualValue = parseFloat(reflection.actual_value) || 0;
        return sum + actualValue;
      }, 0);

      return {
        ...goal,
        unit_name: goal.units?.name,
        reflectionCount: goal.reflections.length,
        totalDuration: goal.reflections.reduce((sum, reflection) => sum + (reflection.reflection_duration || 0), 0),
        totalActualValue: totalActualValue,
        status: goal.status || (goal.is_completed ? 'completed' : 'not_started'),
        parent_goal_title: goal.parent_goal?.title
      };
    });
  },

  /**
   * 目標のステータスを更新する
   * @param {string} goalId - 目標ID
   * @param {string} status - 新しいステータス
   * @returns {Promise<Object>} 更新された目標
   * @throws {Error} 更新に失敗した場合
   */
  async updateGoalStatus(goalId, status) {
    const { data, error } = await supabase
      .from('goals')
      .update({ status })
      .eq('id', goalId)
      .select();

    if (error) {
      console.error('目標のステータス更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 目標とサブ目標を作成する
   * @param {Object} mainGoal - メイン目標データ
   * @param {Array} subGoals - サブ目標データの配列
   * @returns {Promise<Object>} 作成されたメイン目標
   * @throws {Error} 作成に失敗した場合
   */
  async createGoalWithSubGoals(mainGoal, subGoals) {
    try {
      // user_idが設定されていない場合はエラー
      if (!mainGoal.user_id) {
        throw new Error('ユーザーIDが設定されていません');
      }

      // メインゴールのデータを整形
      const mainGoalData = {
        ...mainGoal,
        target_value: mainGoal.target_value != null ? parseFloat(mainGoal.target_value) : null,
        is_completed: mainGoal.is_completed || false,
        status: mainGoal.status || 'not_started'
      };

      let savedMainGoal;
      
      if (mainGoal.id) {
        // 既存の目標を更新
        const { data, error } = await supabase
          .from('goals')
          .update(mainGoalData)
          .eq('id', mainGoal.id)
          .select()
          .single();
        
        if (error) throw error;
        savedMainGoal = data;

        // 既存のサブ目標を取得
        const { data: existingSubGoals, error: fetchError } = await supabase
          .from('goals')
          .select('id, title')
          .eq('parent_goal_id', mainGoal.id);

        if (fetchError) throw fetchError;

        // 既存のサブ目標のIDを保持
        const existingSubGoalIds = existingSubGoals.map(g => g.id);

        // 新しいサブ目標のデータを準備
        const validSubGoals = subGoals
          .filter(subGoal => subGoal.user_id)
          .map(subGoal => ({
            ...subGoal,
            target_value: subGoal.target_value != null ? parseFloat(subGoal.target_value) : null,
            parent_goal_id: savedMainGoal.id,
            is_completed: false,
            status: subGoal.status || 'not_started'
          }));

        if (validSubGoals.length > 0) {
          // 新しいサブ目標を作成
          const { error: insertError } = await supabase
            .from('goals')
            .insert(validSubGoals);

          if (insertError) throw insertError;
        }

        // 既存のサブ目標で、振り返りが紐づいていないものだけを削除
        if (existingSubGoalIds.length > 0) {
          // 振り返りが紐づいているサブ目標を除外
          const { data: goalsWithReflections } = await supabase
            .from('reflections')
            .select('goal_id')
            .in('goal_id', existingSubGoalIds);

          const goalsWithReflectionIds = goalsWithReflections?.map(r => r.goal_id) || [];
          const goalIdsToDelete = existingSubGoalIds.filter(id => !goalsWithReflectionIds.includes(id));

          if (goalIdsToDelete.length > 0) {
            const { error: deleteError } = await supabase
              .from('goals')
              .delete()
              .in('id', goalIdsToDelete);

            if (deleteError) throw deleteError;
          }
        }
      } else {
        // 新しい目標を作成
        const { data, error } = await supabase
          .from('goals')
          .insert([mainGoalData])
          .select()
          .single();
        
        if (error) throw error;
        savedMainGoal = data;

        // サブ目標が存在し、かつuser_idが設定されている場合のみ保存
        if (subGoals && subGoals.length > 0) {
          const validSubGoals = subGoals
            .filter(subGoal => subGoal.user_id)
            .map(subGoal => ({
              ...subGoal,
              target_value: subGoal.target_value != null ? parseFloat(subGoal.target_value) : null,
              parent_goal_id: savedMainGoal.id,
              is_completed: false,
              status: subGoal.status || 'not_started'
            }));

          if (validSubGoals.length > 0) {
            const { error: subGoalsError } = await supabase
              .from('goals')
              .insert(validSubGoals);

            if (subGoalsError) throw subGoalsError;
          }
        }
      }

      return savedMainGoal;
    } catch (error) {
      console.error('目標とサブ目標の作成に失敗しました:', error);
      throw error;
    }
  },
};
