/**
 * @file userService.js
 * @description ユーザー管理に関するAPI呼び出しを管理するサービス
 */

import { supabase } from '../utils/supabase';

/**
 * 組織のメンバー一覧を取得する
 * @param {string} organizationId - 組織ID
 * @returns {Promise<Array>} メンバー一覧
 * @throws {Error} データ取得に失敗した場合
 */
export const getOrganizationMembers = async (organizationId) => {
  if (!organizationId) {
    throw new Error('組織IDが指定されていません');
  }

  try {
    const { data, error, status } = await supabase
      .from('user_profiles')
      .select(`
        id,
        role,
        display_name,
        job_title,
        department,
        organization_id
      `)
      .eq('organization_id', organizationId);

    if (error) {
      console.error('メンバー取得エラー:', { error, status });
      throw new Error(`メンバー取得に失敗しました: ${error.message}`);
    }

    return data || [];
  } catch (error) {
    console.error('メンバー取得エラー:', error);
    throw error;
  }
};

/**
 * ユーザープロフィールを取得する
 * @param {string} [userId] - ユーザーID（指定がない場合は現在のユーザー）
 * @returns {Promise<Object>} ユーザープロフィール
 * @throws {Error} プロフィール取得に失敗した場合
 */
export const getUserProfile = async (userId) => {
  try {
    // 現在のユーザーのセッション情報を取得
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    
    if (userError) {
      console.error('ユーザー情報取得エラー:', userError);
      throw new Error(`ユーザー情報の取得に失敗しました: ${userError.message}`);
    }

    if (!user && !userId) {
      throw new Error('ユーザーが見つかりません');
    }

    const targetUserId = userId || user.id;
    const isCurrentUser = !userId || userId === user.id;

    // user_profilesテーブルからプロフィール情報を取得
    const { data: profile, error: profileError } = await supabase
      .from('user_profiles')
      .select('*')
      .eq('id', targetUserId)
      .single();

    if (profileError) {
      console.error('プロフィール取得エラー:', profileError);
      throw new Error(`プロフィール情報の取得に失敗しました: ${profileError.message}`);
    }

    if (!profile) {
      throw new Error('プロフィールが見つかりません');
    }

    // auth.userとuser_profilesの情報をマージ
    return {
      ...profile,
      email: isCurrentUser ? user.email : undefined,
      emailVerified: isCurrentUser ? user.email_confirmed_at !== null : undefined,
      lastSignInAt: isCurrentUser ? user.last_sign_in_at : undefined,
    };
  } catch (error) {
    console.error('プロフィール取得エラー:', error);
    throw error;
  }
};

/**
 * ユーザーの役割を更新する
 * @param {string} userId - ユーザーID
 * @param {string} role - 新しい役割（'admin' または 'member'）
 * @returns {Promise<Object>} 更新されたユーザープロフィール
 * @throws {Error} 更新に失敗した場合
 */
export const updateUserRole = async (userId, role) => {
  if (!userId) {
    throw new Error('ユーザーIDが指定されていません');
  }

  if (!['admin', 'member'].includes(role)) {
    throw new Error('無効な役割が指定されました');
  }

  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .update({ role })
      .eq('id', userId)
      .single();

    if (error) {
      console.error('役割更新エラー:', error);
      throw new Error(`役割の更新に失敗しました: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('役割更新エラー:', error);
    throw error;
  }
};

/**
 * ユーザーの表示名を更新する
 * @param {string} userId - ユーザーID
 * @param {string} displayName - 新しい表示名
 * @returns {Promise<Object>} 更新されたユーザープロフィール
 * @throws {Error} 更新に失敗した場合
 */
export const updateDisplayName = async (userId, displayName) => {
  if (!userId) {
    throw new Error('ユーザーIDが指定されていません');
  }

  if (!displayName || displayName.trim() === '') {
    throw new Error('表示名が指定されていません');
  }

  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .update({ display_name: displayName.trim() })
      .eq('id', userId)
      .single();

    if (error) {
      console.error('表示名更新エラー:', error);
      throw new Error(`表示名の更新に失敗しました: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error('表示名更新エラー:', error);
    throw error;
  }
}; 