/**
 * @fileoverview 目標詳細チャットサービス
 * @description 目標詳細ページのチャット履歴を管理するサービス
 */

import { supabase } from '../utils/supabase';
import { openaiService } from './openaiService';

/**
 * 目標詳細チャットサービス
 */
export const goalDetailChatService = {
  /**
   * チャット履歴を取得する
   * @param {number} goalId - 目標ID
   * @returns {Promise<Array>} チャット履歴の配列
   */
  async getChatHistories(goalId) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('ユーザーが見つかりません');

      const { data, error } = await supabase
        .from('goal_detail_chats')
        .select('*')
        .eq('goal_id', goalId)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    } catch (error) {
      console.error('チャット履歴の取得に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 新しいチャット履歴を作成する
   * @param {number} goalId - 目標ID
   * @param {Array} messages - メッセージの配列
   * @param {boolean} shouldGenerateTitle - タイトルを生成するかどうか
   * @returns {Promise<Object>} 作成されたチャット履歴
   */
  async createChatHistory(goalId, messages = [], shouldGenerateTitle = false) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('ユーザーが見つかりません');

      let title = '新しいチャット';
      if (shouldGenerateTitle && messages.length > 0) {
        try {
          title = await this.generateChatTitle(messages);
        } catch (error) {
          console.error('タイトル生成に失敗しました:', error);
          // タイトル生成に失敗しても処理は続行
        }
      }

      const { data, error } = await supabase
        .from('goal_detail_chats')
        .insert([{
          goal_id: goalId,
          user_id: user.id,
          messages,
          title,
          last_title_update: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('チャット履歴の作成に失敗しました:', error);
      throw error;
    }
  },

  /**
   * チャット履歴を更新する
   * @param {number} chatId - チャット履歴ID
   * @param {Array} messages - 更新するメッセージの配列
   * @param {boolean} shouldUpdateTitle - タイトルを更新するかどうか
   * @returns {Promise<Object>} 更新されたチャット履歴
   */
  async updateChatHistory(chatId, messages, shouldUpdateTitle = false) {
    try {
      const updateData = { messages };

      if (shouldUpdateTitle) {
        updateData.title = await this.generateChatTitle(messages);
        updateData.last_title_update = new Date().toISOString();
      }

      const { data, error } = await supabase
        .from('goal_detail_chats')
        .update(updateData)
        .eq('id', chatId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('チャット履歴の更新に失敗しました:', error);
      throw error;
    }
  },

  /**
   * チャットのタイトルを生成する
   * @param {Array} messages - メッセージの配列
   * @returns {Promise<string>} 生成されたタイトル
   */
  async generateChatTitle(messages) {
    try {
      if (!Array.isArray(messages) || messages.length === 0) {
        return '無題のチャット';
      }

      // メッセージの内容を結合（最新の5件のみを使用）
      const recentMessages = messages.slice(-5);
      const messageContent = recentMessages
        .map(m => `${m.isUser ? 'ユーザー' : 'AI'}: ${m.content}`)
        .join('\n');

      // OpenAIに送信するメッセージを作成
      const prompt = `
以下のチャット会話の内容を要約して、30文字以内の簡潔なタイトルを生成してください。
タイトルは会話の主要なトピックや目的を表すものにしてください。

会話内容：
${messageContent}
`;

      const title = await openaiService.getChatCompletion([
        { 
          role: 'system', 
          content: 'チャットの内容を30文字以内の簡潔なタイトルにまとめてください。タイトルのみを返してください。' 
        },
        { 
          role: 'user', 
          content: prompt 
        }
      ], 'gpt-3.5-turbo');

      return title.trim() || '無題のチャット';
    } catch (error) {
      console.error('チャットタイトルの生成に失敗しました:', error);
      return '無題のチャット';
    }
  },

  /**
   * AIの応答を取得する
   * @param {Array} messages - メッセージの配列
   * @param {Object} goal - 目標オブジェクト
   * @returns {Promise<string>} AIの応答
   */
  async getAIResponse(messages, goal) {
    try {
      // 最新の情報を含めた応答を生成
      const response = await openaiService.getGoalSpecificResponse(messages, {
        ...goal,
        // 追加の情報を含める
        daysUntilDeadline: goal.deadline ? 
          Math.ceil((new Date(goal.deadline) - new Date()) / (1000 * 60 * 60 * 24)) : 
          null,
        progressRate: goal.target_value && goal.actual_value ? 
          (goal.actual_value / goal.target_value * 100).toFixed(1) : 
          null
      });

      return response;
    } catch (error) {
      console.error('AIレスポンスの取得に失敗しました:', error);
      throw error;
    }
  }
}; 