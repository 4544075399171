/**
 * @file Settings.js
 * @description ユーザー設定ページを表示するReactコンポーネント
 * 
 * このコンポーネントは、ユーザーのプロフィール情報の表示と編集機能を提供します。
 * 
 * @requires React
 * @requires @mui/material
 * @requires ../services/userService
 */

import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Box,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { getUserProfile, updateDisplayName } from '../../services/userService';

/**
 * Settingsは、ユーザー設定ページを表示するReactコンポーネントです。
 * 
 * @returns {JSX.Element} 設定ページを表示するContainer要素
 */
const Settings = () => {
  const [profile, setProfile] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userData = await getUserProfile();
        setProfile(userData);
        setDisplayName(userData.display_name || '');
      } catch (error) {
        console.error('プロフィール取得エラー:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      await updateDisplayName(profile.id, displayName);
      const updatedProfile = await getUserProfile();
      setProfile(updatedProfile);
      setSnackbar({
        open: true,
        message: '設定を保存しました',
        severity: 'success'
      });
    } catch (error) {
      console.error('保存エラー:', error);
      setSnackbar({
        open: true,
        message: '保存に失敗しました',
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          設定
        </Typography>
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            プロフィール情報
          </Typography>
          
          <TextField
            fullWidth
            label="表示名"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            margin="normal"
            helperText={profile?.email ? `未入力の場合は「${profile.email}」が表示されます` : '未入力の場合はメールアドレスが表示されます'}
          />

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              アカウント情報
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              メールアドレス: {profile?.email}
              {profile?.emailVerified && (
                <span style={{ color: 'green', marginLeft: '8px' }}>
                  (認証済み)
                </span>
              )}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              最終ログイン: {profile?.lastSignInAt ? new Date(profile.lastSignInAt).toLocaleString('ja-JP') : '未記録'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
              組織情報
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              役職: {profile?.job_title || '未設定'}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              部署: {profile?.department || '未設定'}
            </Typography>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={saving}
          >
            {saving ? '保存中...' : '保存'}
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Settings; 