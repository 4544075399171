/**
 * @file useAdminRole.js
 * @description 管理者権限を管理するカスタムフック
 */

import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../utils/supabase';

const CACHE_KEY = 'admin_role';
const CACHE_DURATION = 5 * 60 * 1000; // 5分

export const useAdminRole = (userId) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // キャッシュ管理
  const getCache = useCallback(() => {
    try {
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        const { value, timestamp, id } = JSON.parse(cached);
        if (Date.now() - timestamp < CACHE_DURATION && id === userId) {
          return value;
        }
      }
    } catch (e) {
      console.error('キャッシュ読み取りエラー:', e);
    }
    return null;
  }, [userId]);

  const setCache = useCallback((value) => {
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        value,
        timestamp: Date.now(),
        id: userId
      }));
    } catch (e) {
      console.error('キャッシュ保存エラー:', e);
    }
  }, [userId]);

  // 管理者権限チェック
  const checkAdminRole = useCallback(async () => {
    if (!userId) {
      console.log('ユーザーIDがありません');
      setIsAdmin(false);
      setLoading(false);
      return;
    }

    try {
      // キャッシュをチェック
      const cachedValue = getCache();
      if (cachedValue !== null) {
        console.log('キャッシュから管理者権限を取得:', cachedValue);
        setIsAdmin(cachedValue);
        setLoading(false);
        return;
      }

      console.log('管理者権限をDBから確認中...', userId);

      // プロフィールを取得
      const { data, error } = await supabase
        .from('user_profiles')
        .select('id, role')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('プロフィール取得エラー:', error);
        throw error;
      }

      console.log('取得したプロフィール:', data);

      // roleがadminの場合のみ管理者権限を付与（大文字小文字を区別しない）
      const isAdminUser = data?.role?.toLowerCase() === 'admin';
      console.log('管理者権限の判定結果:', {
        role: data?.role,
        isAdminUser,
        userId
      });

      setIsAdmin(isAdminUser);
      setError(null);
      setCache(isAdminUser);

    } catch (err) {
      console.error('管理者権限チェックエラー:', {
        error: err,
        userId,
        message: err.message
      });
      setError(err.message);
      setIsAdmin(false);
    } finally {
      setLoading(false);
    }
  }, [userId, getCache, setCache]);

  useEffect(() => {
    checkAdminRole();
  }, [checkAdminRole]);

  // デバッグ用のログ出力
  useEffect(() => {
    console.log('AdminRole State:', {
      userId,
      isAdmin,
      loading,
      error
    });
  }, [userId, isAdmin, loading, error]);

  return {
    isAdmin,
    loading,
    error,
    refreshRole: checkAdminRole
  };
}; 