/**
 * @fileoverview OpenAI APIサービス
 * @description 振り返りの要約生成を行うサービス
 */

import { PROMPTS } from '../constants/prompts';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

// APIキーの存在と形式を検証
if (!OPENAI_API_KEY || !OPENAI_API_KEY.startsWith('sk-')) {
  console.error('OpenAI APIキーが正しく設定されていません。現在の値:', 
    OPENAI_API_KEY ? 'APIキーが存在しますが形式が不正です' : 'APIキーが未設定です');
  throw new Error('OpenAI APIキーが正しく設定されていません');
}

export const openaiService = {
  /**
   * 振り返りの要約を生成する
   * @param {Array} reflections - 振り返りデータの配列
   * @param {string} model - 使用するモデル（'gpt-3.5-turbo' or 'gpt-4'）
   * @param {string} userInstructions - 追加の指示
   * @returns {Promise<string>} 要約テキスト
   */
  async generateSummary(reflections, model, userInstructions = '') {
    console.log('環境変数の状態:', {
      hasApiKey: !!OPENAI_API_KEY,
      keyPrefix: OPENAI_API_KEY ? OPENAI_API_KEY.substring(0, 5) : 'なし'
    });

    if (!OPENAI_API_KEY) {
      throw new Error('OpenAI APIキーが設定されていません');
    }

    try {
      const systemPrompt = `${PROMPTS.GOAL_SUMMARY.SYSTEM}
${userInstructions ? `\n追加の指示:\n${userInstructions}` : ''}`;

      const userPrompt = reflections.map(r => 
        `日付: ${r.date}\n達成: ${r.achievement}\n学び: ${r.learning}\n改善点: ${r.improvement}`
      ).join('\n\n');

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: model,
          messages: [
            {
              role: 'system',
              content: systemPrompt
            },
            {
              role: 'user',
              content: userPrompt
            }
          ],
          temperature: 0.7,
          max_tokens: 1000,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${errorData.error?.message || '不明なエラー'}`);
      }

      const data = await response.json();
      
      if (!data.choices?.[0]?.message?.content) {
        throw new Error('APIからの応答が不正です');
      }

      return data.choices[0].message.content;
    } catch (error) {
      console.error('API呼び出しエラーの詳細:', error);
      throw error;
    }
  },

  /**
   * プロフィール要約を生成する
   * @param {Object} profileData - 要約に必要なデータ
   * @param {string} model - 使用するモデル（'gpt-3.5-turbo' or 'gpt-4'）
   * @param {string} userInstructions - 追加の指示
   * @param {string} [targetUserId] - 対象ユーザーID
   * @returns {Promise<string>} 要約テキスト
   */
  async generateProfileSummary(profileData, model = 'gpt-4o', userInstructions = '', targetUserId = null) {
    if (!OPENAI_API_KEY) {
      throw new Error('OpenAI APIキーが設定されていません');
    }

    try {
      const { goals, reflections, completedGoals, accumulatedValues } = profileData;

      // データの前処理と要約
      const processedGoals = goals.concat(completedGoals)
        .map(g => ({
          title: g.title,
          is_completed: g.is_completed,
          target_value: g.target_value,
          unit_name: g.unit_name
        }));

      // 制限を1000件に緩和（必要に応じて調整可能）
      const recentReflections = reflections
        .slice(0, 1000)
        .map(r => ({
          date: r.date,
          achievement: this.truncateText(r.achievement, 200), // テキストの長さ制限も緩和
          learning: this.truncateText(r.learning, 200),
          improvement: this.truncateText(r.improvement, 200),
          skills: r.skills,
          actual_value: r.actual_value
        }));

      // 統計データの作成
      const stats = {
        totalGoals: goals.length + completedGoals.length,
        completedGoals: completedGoals.length,
        totalReflections: reflections.length,
        completionRate: ((completedGoals.length / (goals.length + completedGoals.length)) * 100).toFixed(1),
        skillFrequency: this.analyzeSkills(reflections),
        accumulatedValues: accumulatedValues
      };

      const systemPrompt = `${PROMPTS.PROFILE_SUMMARY.SYSTEM}
${userInstructions ? `\n追加の指示:\n${userInstructions}` : ''}
${targetUserId ? `\n対象ユーザーID: ${targetUserId}` : ''}`;

      const userPrompt = `
統計データ:
${JSON.stringify(stats, null, 2)}

目標一覧:
${JSON.stringify(processedGoals, null, 2)}

直近の振り返り:
${JSON.stringify(recentReflections, null, 2)}`;

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: model,
          messages: [
            {
              role: 'system',
              content: systemPrompt
            },
            {
              role: 'user',
              content: userPrompt
            }
          ],
          temperature: 0.7,
          max_tokens: 2000,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${errorData.error?.message || '不明なエラー'}`);
      }

      const responseData = await response.json();
      
      if (!responseData.choices?.[0]?.message?.content) {
        throw new Error('APIからの応答が不正です');
      }

      return responseData.choices[0].message.content;
    } catch (error) {
      console.error('要約の生成に失敗しました:', error);
      throw error;
    }
  },

  /**
   * テキストを指定された長さに切り詰める
   * @param {string} text - 元のテキスト
   * @param {number} maxLength - 最大長
   * @returns {string} 切り詰められたテキスト
   */
  truncateText(text, maxLength) {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  },

  /**
   * スキルの出現頻度を分析
   * @param {Array} reflections - 振り返りデータ
   * @returns {Object} スキルの出現頻度
   */
  analyzeSkills(reflections) {
    const skillCount = {};
    reflections.forEach(reflection => {
      if (reflection.skills) {
        reflection.skills.forEach(skill => {
          skillCount[skill] = (skillCount[skill] || 0) + 1;
        });
      }
    });
    return Object.entries(skillCount)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  },

  /**
   * 目標提案を生成する
   * @param {Array} messages - メッセージ履歴
   * @param {string} model - 使用するモデル
   * @returns {Promise<Object>} 生成された目標提案
   */
  async generateGoalProposal(messages, model) {
    try {
      const response = await this.getChatCompletion([
        { role: 'system', content: PROMPTS.GOAL_GENERATION.SYSTEM },
        ...messages.map(m => ({
          role: m.isUser ? 'user' : 'assistant',
          content: m.content
        }))
      ], model);

      // JSONデータを抽出して解析
      const jsonMatch = response.match(/\{[\s\S]*\}/);
      if (!jsonMatch) {
        throw new Error('有効なJSONデータが生成されませんでした');
      }

      const parsedData = JSON.parse(jsonMatch[0]);
      
      // 必須フィールドの検証
      if (!parsedData.mainGoal?.title || !parsedData.mainGoal?.description || !parsedData.mainGoal?.subGoals?.length) {
        throw new Error('必要な目標情報が不足しています');
      }

      return parsedData;
    } catch (error) {
      console.error('目標提案の生成に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 目標の実現可能性を評価する
   * @param {Object} goal - 評価する目標
   * @param {string} model - 使用するモデル
   * @returns {Promise<string>} 評価結果
   */
  async evaluateGoalFeasibility(goal, model) {
    const messages = [
      {
        role: 'system',
        content: `目標の実現可能性をSMART基準に基づいて評価し、
改善点があれば具体的な提案をしてください。`
      },
      {
        role: 'user',
        content: JSON.stringify(goal)
      }
    ];

    return await this.getChatCompletion(messages, model);
  },

  /**
   * ChatGPT APIを使用してチャット応答を取得する
   * @param {Array} messages - メッセージ履歴
   * @param {string} model - 使用するモデル
   * @param {number} [maxTokens=1000] - 生成する最大トークン数
   * @returns {Promise<string>} AIからの応答
   */
  async getChatCompletion(messages, model, maxTokens = 1000) {
    if (!OPENAI_API_KEY) {
      throw new Error('OpenAI APIキーが設定されていません');
    }

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: model,
          messages: messages.map(msg => ({
            role: msg.role,
            content: msg.content
          })),
          temperature: 0.7,
          max_tokens: maxTokens,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${errorData.error?.message || '不明なエラー'}`);
      }

      const data = await response.json();
      
      if (!data.choices?.[0]?.message?.content) {
        throw new Error('APIからの応答が不正です');
      }

      return data.choices[0].message.content;
    } catch (error) {
      console.error('ChatGPT APIの呼び出しに失敗しました:', error);
      throw error;
    }
  },

  /**
   * チャットタイトルを生成する
   * @param {Array} messages - タイトル生成に使用するメッセージ
   * @returns {Promise<string>} 生成されたタイトル
   */
  async generateChatTitle(messages) {
    try {
      // メッセージ内容を文字列として結合（最初の2つのメッセージのみを使用）
      const relevantMessages = messages.slice(0, 2);
      const messageContent = relevantMessages
        .map(m => m.content)
        .join('\n');

      const response = await this.getChatCompletion([
        { 
          role: 'system', 
          content: 'チャットの内容を30文字以内の簡潔なタイトルにまとめてください。タイトルのみを返してください。' 
        },
        { 
          role: 'user', 
          content: messageContent 
        }
      ], 'gpt-3.5-turbo');

      return response.trim();
    } catch (error) {
      console.error('チャットタイトルの生成に失敗しました:', error);
      return '無題のチャット';
    }
  },

  /**
   * ダッシュボード用の挨拶メッセージを生成する
   * @param {Object} data - ユーザーの活動データ
   * @param {Array} data.goals - 目標一覧
   * @param {Array} data.reflections - 振り返り一覧
   * @returns {Promise<string>} 生成されたメッセージ
   */
  async generateDashboardGreeting(data) {
    const lastGreetingTime = localStorage.getItem('lastGreetingTime');
    const now = new Date();
    
    // 前回の挨拶から3時間以上経過しているか確認
    const shouldGenerateNew = !lastGreetingTime || 
      (now - new Date(lastGreetingTime)) > (3 * 60 * 60 * 1000);

    if (!shouldGenerateNew) {
      return localStorage.getItem('lastGreetingMessage') || 
        'いらっしゃいませ！目標達成に向けて一緒に頑張りましょう！';
    }

    try {
      const { goals, reflections, currentTime } = data;
      
      // データの前処理
      const now = new Date(currentTime);
      const hour = now.getHours();
      const dayOfWeek = now.getDay();
      
      const timeContext = {
        hour,
        dayOfWeek,
        timeOfDay: hour < 12 ? '朝' : hour < 18 ? '午後' : '夜',
        isWeekend: dayOfWeek === 0 || dayOfWeek === 6
      };

      const incompleteGoals = goals.filter(g => !g.is_completed);
      const upcomingDeadlines = incompleteGoals
        .filter(g => g.deadline)
        .sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
        .slice(0, 3);
      
      const recentReflections = reflections
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

      const userPrompt = `
現在の状況:
- 時間帯: ${timeContext.timeOfDay}
- 曜日: ${timeContext.isWeekend ? '週末' : '平日'}
- 未完了の目標: ${incompleteGoals.length}件
- 直近の締め切り: ${upcomingDeadlines.map(g => `${g.title}(${g.deadline})`).join(', ')}
- 最近の振り返り: ${recentReflections.length}件
- 最終振り返り日: ${recentReflections[0]?.date || 'なし'}
`;

      const response = await this.getChatCompletion([
        { role: 'system', content: PROMPTS.DASHBOARD_GREETING.SYSTEM },
        { role: 'user', content: userPrompt }
      ], 'gpt-4o');

      // 生成した挨拶を保存
      localStorage.setItem('lastGreetingTime', now.toISOString());
      localStorage.setItem('lastGreetingMessage', response.trim());
      
      return response.trim();
    } catch (error) {
      console.error('挨拶メッセージの生成に失敗しました:', error);
      return 'いらっしゃいませ！目標達成に向けて一緒に頑張りましょう！';
    }
  },

  /**
   * 目標に対する応援メッセージとネクストアクションを生成する
   * @param {Object} data - 目標と振り返りのデータ
   * @param {Object} data.goal - 目標データ
   * @param {Array} data.reflections - 振り返りデータの配列
   * @returns {Promise<string>} 生成されたメッセージ
   */
  async generateGoalSupportMessage(data) {
    const { goal, reflections } = data;
    
    // キャッシュキーの生成
    const updateKey = [
      goal.status,
      goal.is_completed,
      goal.actual_value,
      reflections.length
    ].join('_');
    const cacheKey = `goal_support_${goal.id}_${updateKey}`;
    
    // キャッシュの確認
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { message, timestamp } = JSON.parse(cachedData);
      const now = new Date().getTime();
      // 24時間以内のキャッシュであれば、それを使用
      if (now - timestamp < 24 * 60 * 60 * 1000) {
        return message;
      }
    }

    // リトライ回数の設定
    const maxRetries = 3;
    let retryCount = 0;

    while (retryCount < maxRetries) {
    try {
      // 残り日数の計算
      const daysUntilDeadline = goal.deadline ? 
        Math.ceil((new Date(goal.deadline) - new Date()) / (1000 * 60 * 60 * 24)) : 
        null;

      // 達成率の計算
      let progressRate = null;
      if (goal.target_value && goal.actual_value) {
        progressRate = (goal.actual_value / goal.target_value * 100).toFixed(1);
      }

        // 直近の振り返り（最新の5件を使用）
        // TODO: プレミアムプランでは振り返りの数を増やす
        const recentReflections = reflections
        .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 5);

        // 振り返りの要約を作成
        const reflectionsSummary = recentReflections.map(r => {
          // 各フィールドを300文字に制限
          // TODO: プレミアムプランでは振り返りの数を増やす
          const truncate = (text, length = 300) => 
            text?.length > length ? text.substring(0, length) + '...' : text;

          return `
日付: ${r.date}
達成: ${truncate(r.achievement)}
学び: ${truncate(r.learning)}
改善: ${truncate(r.improvement)}`;
        });

      const userPrompt = `
目標情報：
タイトル: ${goal.title}
説明: ${goal.description}
ステータス: ${goal.status}
${daysUntilDeadline ? `残り日数: ${daysUntilDeadline}日` : '期限: 未設定'}
${progressRate ? `達成率: ${progressRate}%` : ''}
${goal.target_value ? `目標値: ${goal.target_value}` : ''}
${goal.actual_value ? `現在値: ${goal.actual_value}` : ''}

直近の振り返り：${reflectionsSummary.length > 0 ? reflectionsSummary.join('\n---') : '振り返りはまだありません'}`;

      const response = await this.getChatCompletion([
        { role: 'system', content: PROMPTS.GOAL_SUPPORT.SYSTEM },
        { role: 'user', content: userPrompt }
        // TODO: プレミアムプランではモデルを変更し、max_tokensを増やす
        ], 'gpt-3.5-turbo', 300); // max_tokensを300に制限

      // キャッシュの保存
      localStorage.setItem(cacheKey, JSON.stringify({
        message: response,
        timestamp: new Date().getTime()
      }));

      return response;
    } catch (error) {
        console.error(`サポートメッセージの生成に失敗しました (試行 ${retryCount + 1}/${maxRetries}):`, error);
        retryCount++;
        
        // 最後の試行で失敗した場合
        if (retryCount === maxRetries) {
          // 以前のキャッシュがあれば、それを延長して使用
          if (cachedData) {
            const { message } = JSON.parse(cachedData);
            localStorage.setItem(cacheKey, JSON.stringify({
              message,
              timestamp: new Date().getTime()
            }));
            return message;
          }
          
          // 状況に応じたフォールバックメッセージを返す
          return this.getContextualFallbackMessage(goal);
        }
        
        // 次の試行までの待機時間を設定（指数バックオフ）
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 1000));
      }
    }
  },

  /**
   * 状況に応じたフォールバックメッセージを生成
   * @param {Object} goal - 目標データ
   * @returns {string} フォールバックメッセージ
   */
  getContextualFallbackMessage(goal) {
    if (goal.is_completed) {
      return '目標を達成されて、本当におめでとうございます！次の目標に向けて、一緒に頑張っていきましょう！';
    }

    if (goal.deadline) {
      const daysUntilDeadline = Math.ceil((new Date(goal.deadline) - new Date()) / (1000 * 60 * 60 * 24));
      if (daysUntilDeadline < 7) {
        return '期限が近づいていますね。一緒に目標達成に向けて頑張りましょう！';
      }
    }

    if (goal.status === 'in_progress') {
      return '着実に進めていますね！一歩一歩、目標に近づいていきましょう！';
    }

    return 'がんばっていますね！一緒に目標達成を目指しましょう！';
  },

  /**
   * 目標に特化したチャット応答を生成する
   * @param {Array} messages - チャット履歴
   * @param {Object} goal - 目標データ
   * @returns {Promise<string>} 生成された応答
   */
  async getGoalSpecificResponse(messages, goal) {
    try {
      const goalContext = {
        title: goal.title,
        description: goal.description,
        deadline: goal.deadline,
        status: goal.status,
        is_completed: goal.is_completed,
        target_value: goal.target_value,
        actual_value: goal.actual_value,
        unit_name: goal.unit_name
      };

      const systemPrompt = PROMPTS.GOAL_CHAT_RESPONSE.SYSTEM;
      const userPrompt = `
目標情報:
${JSON.stringify(goalContext, null, 2)}

チャット履歴:
${messages.map(m => `${m.isUser ? 'ユーザー' : 'アシスタント'}: ${m.content}`).join('\n')}`;

      const response = await this.getChatCompletion([
        { role: 'system', content: systemPrompt },
        { role: 'user', content: userPrompt }
      ], 'gpt-4o');

      return response;
    } catch (error) {
      console.error('目標固有の応答生成に失敗しました:', error);
      throw error;
    }
  },

  /**
   * チャット履歴から目標に関連するタスクを生成する
   * @param {Array} messages - チャット履歴
   * @param {Object} goal - 目標データ
   * @returns {Promise<Array>} 生成されたタスクの配列
   */
  async generateTasksFromChat(messages, goal) {
    try {
      const goalContext = {
        title: goal.title,
        description: goal.description,
        deadline: goal.deadline,
        status: goal.status,
        is_completed: goal.is_completed,
        target_value: goal.target_value,
        actual_value: goal.actual_value,
        unit_name: goal.unit_name
      };

      const systemPrompt = PROMPTS.TASK_GENERATION.SYSTEM;
      const userPrompt = `
目標情報:
${JSON.stringify(goalContext, null, 2)}

チャット履歴:
${messages.map(m => `${m.isUser ? 'ユーザー' : 'アシスタント'}: ${m.content}`).join('\n')}`;

      const response = await this.getChatCompletion([
        { role: 'system', content: systemPrompt },
        { role: 'user', content: userPrompt }
      ], 'gpt-4o');

      // JSONデータを抽出して解析
      const jsonMatch = response.match(/\{[\s\S]*\}/);
      if (!jsonMatch) {
        throw new Error('有効なJSONデータが生成されませんでした');
      }

      const parsedData = JSON.parse(jsonMatch[0]);
      
      // タスクデータの検証
      if (!Array.isArray(parsedData.tasks) || parsedData.tasks.length === 0) {
        throw new Error('タスクが生成されませんでした');
      }

      return parsedData.tasks;
    } catch (error) {
      console.error('タスク生成に失敗しました:', error);
      throw error;
    }
  },
};
