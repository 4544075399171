/**
 * @file UserList.js
 * @description ユーザー一覧を表示・管理するコンポーネント
 */

import React, { useState, useCallback, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Alert,
  Snackbar,
  FormControl,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Tooltip,
  IconButton,
  Button,
  Chip,
  Divider,
  Collapse
} from '@mui/material';
import { ViewList, ViewModule, Person, Search, FilterList, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { updateUserRole } from '../../services/userService';
import { useAuth } from '../../hooks/useAuth';
import { goalService } from '../../services/goalService';
import { projectService } from '../../services/projectService';
import { styled } from '@mui/material/styles';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { useProjectData } from '../../hooks/useProjectData';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const UserList = memo(({ 
  members, 
  onMemberUpdate,
  viewMode = 'card',
  onViewModeChange
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [openSelect, setOpenSelect] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [expandedGoals, setExpandedGoals] = useState({});
  const [userGoals, setUserGoals] = useState({});
  const [goalsLoading, setGoalsLoading] = useState(false);
  const [userProjects, setUserProjects] = useState({});

  const filteredMembers = members.filter(member => {
    const searchLower = searchQuery.toLowerCase();
    return (
      member.display_name?.toLowerCase().includes(searchLower) ||
      member.department?.toLowerCase().includes(searchLower) ||
      member.job_title?.toLowerCase().includes(searchLower) ||
      member.skills?.some(skill => skill.toLowerCase().includes(searchLower)) ||
      member.certifications?.some(cert => cert.toLowerCase().includes(searchLower)) ||
      member.goals?.some(goal => goal.toLowerCase().includes(searchLower))
    );
  });

  useEffect(() => {
    const fetchUserProjectsAndGoals = async () => {
      setGoalsLoading(true);
      const projectsMap = {};
      const goalsMap = {};
      
      try {
        await Promise.all(
          members.map(async (member) => {
            try {
              const [projects, goals] = await Promise.all([
                projectService.fetchProjects(member.id),
                goalService.fetchGoals(member.id, false)
              ]);
              
              const groupedGoals = goals.reduce((acc, goal) => {
                if (!goal.parent_goal_id) {
                  const projectId = goal.project_id || 'no_project';
                  if (!acc[projectId]) {
                    acc[projectId] = [];
                  }
                  acc[projectId].push(goal);
                }
                return acc;
              }, {});

              projectsMap[member.id] = projects;
              goalsMap[member.id] = {
                groupedGoals,
                allGoals: goals
              };
            } catch (error) {
              console.error(`Failed to fetch data for user ${member.id}:`, error);
              projectsMap[member.id] = [];
              goalsMap[member.id] = { groupedGoals: {}, allGoals: [] };
            }
          })
        );
        
        setUserProjects(projectsMap);
        setUserGoals(goalsMap);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setGoalsLoading(false);
      }
    };

    if (members.length > 0) {
      fetchUserProjectsAndGoals();
    }
  }, [members]);

  const handleExpandClick = (memberId) => {
    setExpandedGoals(prev => ({
      ...prev,
      [memberId]: !prev[memberId]
    }));
  };

  const handleRoleChange = useCallback(async (userId, newRole) => {
    try {
      setLoading(true);
      await updateUserRole(userId, newRole);
      setSuccess(true);
      onMemberUpdate();
    } catch (err) {
      console.error('役割更新エラー:', err);
      setError('役割の更新中にエラーが発生しました。');
    } finally {
      setLoading(false);
      setOpenSelect(null);
    }
  }, [onMemberUpdate]);

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      onViewModeChange?.(newMode);
    }
  };

  const handleViewProfile = (memberId) => {
    navigate(`/admin/members/${memberId}/profile`);
  };

  const renderRoleSelect = (member) => {
    const isCurrentUser = member.id === user?.id;
    const isAdmin = member.role === 'admin';

    const select = (
      <FormControl size="small">
        <Select
          value={member.role}
          onChange={(e) => handleRoleChange(member.id, e.target.value)}
          disabled={loading || isCurrentUser}
          open={openSelect === member.id}
          onOpen={() => setOpenSelect(member.id)}
          onClose={() => setOpenSelect(null)}
          aria-label={`${member.display_name || '未設定'}の権限`}
        >
          <MenuItem value="member">メンバー</MenuItem>
          <MenuItem value="admin">管理者</MenuItem>
        </Select>
      </FormControl>
    );

    if (isCurrentUser && isAdmin) {
      return (
        <Tooltip title="自分自身の管理者権限は変更できません" arrow>
          <span>{select}</span>
        </Tooltip>
      );
    }

    return select;
  };

  const renderListView = (members) => (
    <TableContainer component={Paper}>
      <Table aria-label="ユーザー一覧">
        <TableHead>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell>部署</TableCell>
            <TableCell>役職</TableCell>
            <TableCell>権限</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <TableRow 
              key={member.id}
              sx={member.id === user?.id ? { backgroundColor: 'action.hover' } : {}}
            >
              <TableCell>{member.display_name || '未設定'}</TableCell>
              <TableCell>{member.department || '未設定'}</TableCell>
              <TableCell>{member.job_title || '未設定'}</TableCell>
              <TableCell>{renderRoleSelect(member)}</TableCell>
              <TableCell>
                <Tooltip title="プロフィールを表示">
                  <IconButton 
                    size="small" 
                    onClick={() => handleViewProfile(member.id)}
                  >
                    <Person />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderGoalWithChildren = (goal, allGoals, level = 0) => {
    const childGoals = allGoals.filter(g => g.parent_goal_id === goal.id);

    return (
      <Box key={goal.id}>
        <Box sx={{ 
          mb: 1,
          pb: 1,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          pl: level * 2
        }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              mb: 0.5
            }}>
              {level > 0 && (
                <Box
                  component="span"
                  sx={{
                    width: '12px',
                    height: '12px',
                    borderLeft: '2px solid',
                    borderBottom: '2px solid',
                    borderColor: 'primary.main',
                    display: 'inline-block',
                    mr: 0.5
                  }}
                />
              )}
              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                {goal.title}
              </Typography>
            </Box>
            {goal.deadline && (
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ 
                  display: 'block',
                  ml: level > 0 ? 3 : 0
                }}
              >
                期限: {new Date(goal.deadline).toLocaleDateString()}
              </Typography>
            )}
          </Box>
          <Chip 
            label={goal.status === 'completed' ? '完了' : 
                   goal.status === 'in_progress' ? '進行中' : '未着手'}
            size="small"
            color={goal.status === 'completed' ? 'success' : 
                  goal.status === 'in_progress' ? 'primary' : 'default'}
            variant="outlined"
          />
        </Box>
        {childGoals.length > 0 && (
          <Box>
            {childGoals.map(childGoal => 
              renderGoalWithChildren(childGoal, allGoals, level + 1)
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderGoals = (member) => {
    const userData = userGoals[member.id] || { groupedGoals: {}, allGoals: [] };
    const { groupedGoals, allGoals } = userData;
    const projects = userProjects[member.id] || [];
    const isExpanded = expandedGoals[member.id] || false;

    const hasAnyGoals = allGoals.length > 0;
    if (!hasAnyGoals) {
      return (
        <Typography variant="body2" color="text.secondary">
          未設定
        </Typography>
      );
    }

    return (
      <Box>
        <Box sx={{ 
          position: 'relative',
          maxHeight: isExpanded ? 'none' : '300px',
          overflow: 'hidden'
        }}>
          <Box>
            {projects.map(project => {
              const goals = groupedGoals[project.id] || [];
              if (goals.length === 0) return null;

              return (
                <Box key={project.id} sx={{ mb: 2 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 1,
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: 'white',
                    px: 1.5,
                    py: 0.75,
                    borderRadius: 1,
                    boxShadow: 1
                  }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: 'medium',
                        color: 'inherit'
                      }}
                    >
                      {project.name}
                    </Typography>
                    {project.end_date && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          ml: 1,
                          color: 'rgba(255, 255, 255, 0.8)'
                        }}
                      >
                        (期限: {new Date(project.end_date).toLocaleDateString()})
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ 
                    pl: 2,
                    ml: 1,
                    borderLeft: '2px solid',
                    borderLeftColor: 'primary.light'
                  }}>
                    {goals.map(goal => renderGoalWithChildren(goal, allGoals))}
                  </Box>
                </Box>
              );
            })}
            {groupedGoals.no_project && groupedGoals.no_project.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1,
                  backgroundColor: (theme) => theme.palette.grey[200],
                  px: 1.5,
                  py: 0.75,
                  borderRadius: 1
                }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    その他の目標
                  </Typography>
                </Box>
                <Box sx={{ 
                  pl: 2,
                  ml: 1,
                  borderLeft: '2px solid',
                  borderLeftColor: 'grey.300'
                }}>
                  {groupedGoals.no_project.map(goal => 
                    renderGoalWithChildren(goal, allGoals)
                  )}
                </Box>
              </Box>
            )}
          </Box>
          {!isExpanded && hasAnyGoals && (
            <Box sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '100px',
              background: 'linear-gradient(transparent, white)',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              pb: 2
            }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleExpandClick(member.id)}
              >
                さらに見る
              </Button>
            </Box>
          )}
        </Box>
        {isExpanded && hasAnyGoals && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleExpandClick(member.id)}
            >
              閉じる
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  const renderCardView = (members) => (
    <Grid container spacing={2}>
      {members.map((member) => (
        <Grid item xs={12} sm={6} md={4} key={member.id}>
          <Card 
            sx={{
              ...member.id === user?.id ? { backgroundColor: 'action.hover' } : {},
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">
                  {member.display_name || '未設定'}
                </Typography>
                <Chip 
                  label={member.department || '未設定'} 
                  size="small" 
                  color="primary" 
                  variant="outlined"
                />
              </Box>

              <Typography color="textSecondary" gutterBottom>
                役職: {member.job_title || '未設定'}
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  スキル
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(member.skills || []).map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                  {(!member.skills || member.skills.length === 0) && (
                    <Typography variant="body2" color="text.secondary">
                      未設定
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  保有資格
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(member.certifications || []).map((cert, index) => (
                    <Chip
                      key={index}
                      label={cert}
                      size="small"
                      color="success"
                      variant="outlined"
                    />
                  ))}
                  {(!member.certifications || member.certifications.length === 0) && (
                    <Typography variant="body2" color="text.secondary">
                      未設定
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="primary" gutterBottom>
                  目標
                </Typography>
                {renderGoals(member)}
              </Box>
            </CardContent>

            <CardActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography color="textSecondary">
                    権限:
                  </Typography>
                  {renderRoleSelect(member)}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  <Button
                    size="small"
                    startIcon={<Person />}
                    onClick={() => handleViewProfile(member.id)}
                    variant="contained"
                  >
                    詳細プロフィール
                  </Button>
                </Box>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" component="h2">
            メンバー一覧
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="search">
                <Search />
              </IconButton>
              <Box
                component="input"
                sx={{
                  ml: 1,
                  flex: 1,
                  border: 'none',
                  outline: 'none',
                  fontSize: '1rem',
                  '&::placeholder': {
                    color: 'text.secondary',
                  }
                }}
                placeholder="名前、部署、スキルなどで検索..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Paper>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="outlined"
                startIcon={<FilterList />}
                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              >
                フィルター
              </Button>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="表示モード"
                size="small"
              >
                <ToggleButton value="card" aria-label="カード表示">
                  <ViewModule />
                </ToggleButton>
                <ToggleButton value="list" aria-label="リスト表示">
                  <ViewList />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
        {searchQuery && (
          <Typography variant="body2" color="text.secondary">
            検索結果: {filteredMembers.length} 件
          </Typography>
        )}
      </Box>

      {viewMode === 'list' ? renderListView(filteredMembers) : renderCardView(filteredMembers)}

      <Snackbar 
        open={error !== null} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert severity="success" onClose={() => setSuccess(false)}>
          権限を更新しました
        </Alert>
      </Snackbar>
    </>
  );
});

export default UserList; 