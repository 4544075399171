/**
 * @fileoverview 目標の達成進捗を表示するチャートコンポーネント
 * @module AchievementProgressChart
 */

import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Line, ComposedChart, ReferenceLine } from 'recharts';
import { useReflectionData } from '../../hooks/useReflectionData';
import { useGoalData } from '../../hooks/useGoalData';
import TimeRangeSelector from './common/TimeRangeSelector';
import DateRangePicker from './common/DateRangePicker';
import { getKey, formatXAxisLabel, getNextDate, getDefaultDateRange } from './common/chartUtils';

/**
 * 目標の達成進捗を表示するチャートコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} props.goalID - 目標のID
 * @returns {JSX.Element} AchievementProgressChartコンポーネント
 */
const AchievementProgressChart = ({ goalID }) => {
  const [timeRange, setTimeRange] = useState('daily');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartData, setChartData] = useState([]);

  const { reflections } = useReflectionData(goalID);
  const { goals } = useGoalData(null, goalID);  // プロジェクトIDはnull、目標IDを渡す
  const goal = goals[0];

  useEffect(() => {
    setDefaultDateRange();
  }, [timeRange]);

  useEffect(() => {
    if (reflections.length > 0 && goal) {
      processChartData();
    }
  }, [reflections, goal, timeRange, startDate, endDate]);

  /**
   * デフォルトの日付範囲を設定する
   */
  const setDefaultDateRange = () => {
    const { startDate, endDate } = getDefaultDateRange(timeRange);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  /**
   * チャートデータを処理する
   */
  const processChartData = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start) || isNaN(end)) {
      console.error('Invalid start or end date:', startDate, endDate);
      return;
    }
    const data = [];
    let currentDate = new Date(start);
    let cumulativeValue = 0;

    while (currentDate <= end) {
      const key = getKey(currentDate, timeRange);
      if (key) {
        const value = reflections.reduce((sum, reflection) => {
          const reflectionDate = new Date(reflection.date);
          if (getKey(reflectionDate, timeRange) === key && reflection.actual_value !== null) {
            return sum + parseFloat(reflection.actual_value);  // 文字列を数値に変換
          }
          return sum;
        }, 0);

        cumulativeValue += value;
        data.push({
          date: key,
          value: value,
          cumulativeValue: cumulativeValue,
        });

        currentDate = getNextDate(currentDate, timeRange);
      }
    }

    setChartData(data);
  };

  /**
   * 時間範囲の変更を処理する
   * @param {Event} _ - イベントオブジェクト（未使用）
   * @param {string} newRange - 新しい時間範囲
   */
  const handleTimeRangeChange = (_, newRange) => {
    if (newRange !== null) {
      setTimeRange(newRange);
    }
  };

  /**
   * 日付の変更を処理する
   * @param {Event} event - 日付変更イベント
   * @param {boolean} isStart - 開始日かどうか
   */
  const handleDateChange = (event, isStart) => {
    const { value } = event.target;
    if (isStart) {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  if (!goal) {
    return <Typography>目標データを読み込み中...</Typography>;
  }

  return (
    <Box sx={{ mt: 4, overflowX: 'auto' }}>
      <Box sx={{ minWidth: '800px' }}>  
        <Typography variant="h6" sx={{ mb: 2 }}>
          実績値の推移
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <TimeRangeSelector
            timeRange={timeRange}
            onTimeRangeChange={handleTimeRangeChange}
          />
        </Box>

        <DateRangePicker
          timeRange={timeRange}
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />

        <Box sx={{ height: 400, mt: 2 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData}
              margin={{ top: 30, right: 50, left: 20, bottom: 25 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={(date) => formatXAxisLabel(date, timeRange)} />
              <YAxis domain={[0, goal?.target_value ? parseFloat(goal.target_value) : 'auto']} />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" name="実績値" />
              <Line type="linear" dataKey="cumulativeValue" stroke="#82ca9d" name="累計実績値" />
              {goal?.target_value && (
                <ReferenceLine y={parseFloat(goal.target_value)} stroke="red" strokeDasharray="3 3" label={{ value: '目標値', position: 'top', fill: 'red', offset: 15 }} />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Box>  
    </Box>
  );
};

export default AchievementProgressChart;
