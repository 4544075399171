/**
 * @fileoverview タスクデータを取得・管理するカスタムフック
 * @module useTaskData
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { taskService } from '../services/taskService';

/**
 * タスクデータを取得・管理するカスタムフック
 * @param {string|null} goalId - 目標ID
 * @returns {Object} タスクデータ、ローディング状態、エラー状態、データ操作関数を含むオブジェクト
 */
export const useTaskData = (goalId = null) => {
  const [userId, setUserId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId && goalId) {
      fetchTasks();
    }
  }, [userId, goalId]);

  const fetchTasks = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await taskService.fetchTasks(userId, goalId);
      setTasks(data);
    } catch (err) {
      console.error('タスクデータの取得に失敗しました:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTask = async (content, priority = 'medium', dueDate = null) => {
    try {
      const newTask = await taskService.createTask({
        user_id: userId,
        goal_id: goalId,
        content,
        is_completed: false,
        priority,
        due_date: dueDate
      });
      await fetchTasks();
      return newTask;
    } catch (error) {
      console.error('タスクの追加に失敗しました:', error);
      setError(error.message);
    }
  };

  const handleEditTask = async (taskId, content, details = {}) => {
    try {
      await taskService.updateTask(taskId, {
        content,
        ...details,
        updated_at: new Date()
      });
      await fetchTasks();
    } catch (error) {
      console.error('タスクの更新に失敗しました:', error);
      setError(error.message);
    }
  };

  const handleToggleTask = async (taskId, isCompleted) => {
    try {
      await taskService.updateTask(taskId, { 
        is_completed: isCompleted,
        updated_at: new Date()
      });
      await fetchTasks();
      return true;
    } catch (error) {
      console.error('タスクの状態更新に失敗しました:', error);
      setError(error.message);
      return false;
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await taskService.deleteTask(taskId);
      await fetchTasks();
    } catch (error) {
      console.error('タスクの削除に失敗しました:', error);
      setError(error.message);
    }
  };

  return {
    userId,
    tasks,
    loading,
    error,
    fetchTasks,
    handleAddTask,
    handleEditTask,
    handleToggleTask,
    handleDeleteTask,
  };
};