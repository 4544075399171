/**
 * @file AdminPage.js
 * @description 管理者用のダッシュボード画面
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import UserList from '../components/admin/UserList';
import { useAuth } from '../hooks/useAuth';
import { useAdminRole } from '../hooks/useAdminRole';
import { getUserProfile, getOrganizationMembers } from '../services/userService';
import { Navigate } from 'react-router-dom';

const VIEW_MODE_KEY = 'admin_view_mode';
const AUTO_REFRESH_INTERVAL = 30000; // 30秒ごとに更新

const AdminPage = () => {
  const { user, isAuthenticated, loading: authLoading } = useAuth();
  const { isAdmin, loading: adminLoading, error: adminError } = useAdminRole(user?.id);
  
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [members, setMembers] = useState([]);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem(VIEW_MODE_KEY) || 'card';
  });
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const refreshTimerRef = useRef(null);

  // 状態をログ出力
  useEffect(() => {
    console.log('AdminPage State:', {
      user: user?.id,
      isAuthenticated,
      isAdmin,
      loading: {
        auth: authLoading,
        admin: adminLoading,
        page: loading
      },
      error: {
        admin: adminError,
        page: error
      },
      organizationId
    });
  }, [user, isAuthenticated, isAdmin, authLoading, adminLoading, loading, adminError, error, organizationId]);

  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);
    localStorage.setItem(VIEW_MODE_KEY, newMode);
  };

  const fetchMembers = useCallback(async (orgId, isInitialLoad = false) => {
    if (!orgId) {
      console.log('組織IDが未設定のため、メンバー取得をスキップ');
      return;
    }

    try {
      if (isInitialLoad) {
        setLoading(true);
      } else {
        setRefreshing(true);
      }
      setError(null);

      console.log('メンバー取得開始:', orgId);
      const membersList = await getOrganizationMembers(orgId);
      console.log('取得したメンバー:', membersList);
      
      setMembers(membersList);
      setLastUpdated(new Date());
    } catch (err) {
      console.error('メンバー取得エラー:', err);
      setError('組織メンバーの取得中にエラーが発生しました。しばらく待ってから再試行してください。');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, []);

  const handleRefresh = useCallback(async () => {
    if (!organizationId || refreshing) return;
    await fetchMembers(organizationId);
  }, [organizationId, refreshing, fetchMembers]);

  // プロフィール情報の取得
  useEffect(() => {
    const fetchProfile = async () => {
      if (!user?.id || !isAdmin) {
        console.log('プロフィール取得をスキップ:', { userId: user?.id, isAdmin });
        return;
      }
      
      try {
        console.log('プロフィール取得開始:', user.id);
        const profile = await getUserProfile(user.id);
        console.log('取得したプロフィール:', profile);

        if (!profile?.organization_id) {
          console.error('組織情報が見つかりません');
          setError('組織情報が見つかりません。');
          return;
        }
        setOrganizationId(profile.organization_id);
        await fetchMembers(profile.organization_id, true);
      } catch (err) {
        console.error('プロフィール取得エラー:', err);
        setError('プロフィール情報の取得中にエラーが発生しました。');
      }
    };

    if (isAdmin && !organizationId) {
      fetchProfile();
    }
  }, [user?.id, isAdmin, organizationId, fetchMembers]);

  // 自動更新の設定
  useEffect(() => {
    if (organizationId && !refreshTimerRef.current && isAdmin) {
      console.log('自動更新タイマーを設定:', AUTO_REFRESH_INTERVAL);
      refreshTimerRef.current = setInterval(handleRefresh, AUTO_REFRESH_INTERVAL);
    }
    return () => {
      if (refreshTimerRef.current) {
        console.log('自動更新タイマーをクリア');
        clearInterval(refreshTimerRef.current);
        refreshTimerRef.current = null;
      }
    };
  }, [organizationId, handleRefresh, isAdmin]);

  // ローディング中の表示
  if (authLoading || adminLoading) {
    console.log('ローディング中...', { authLoading, adminLoading });
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // 未認証の場合はログインページへリダイレクト
  if (!isAuthenticated) {
    console.log('未認証のためリダイレクト');
    return <Navigate to="/login" replace />;
  }

  // 管理者権限がない場合のエラー表示
  if (!isAdmin || adminError) {
    console.log('管理者権限なし:', { isAdmin, adminError });
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          この画面にアクセスする権限がありません。
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        管理画面
      </Typography>
      <Paper sx={{ p: 3, mt: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        ) : (
          <UserList 
            members={members} 
            organizationId={organizationId}
            onMemberUpdate={handleRefresh}
            viewMode={viewMode}
            onViewModeChange={handleViewModeChange}
          />
        )}
      </Paper>
    </Box>
  );
};

export default AdminPage; 