/**
 * @fileoverview 目標カードコンポーネント
 * 
 * このファイルは、個々の目標を表示するためのカードコンポーネントを定義します。
 * 目標の詳細情報、進捗状況、子目標、および関連するアクションボタンを表示します。
 * 
 * @requires React
 * @requires @mui/material
 * @requires @mui/icons-material
 * @requires ../../utils/commonUtils
 * @requires ../common/GoalProgress
 * @requires ../common/MarkdownRenderer
 */

import React from 'react';
import { Card, CardContent, Typography, Box, Button, List, ListItem, ListItemText, Collapse, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReferenceLinks, formatDate, formatNumber } from '../../utils/commonUtils';
import GoalProgress from '../common/GoalProgress';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * 目標カードコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 表示する目標オブジェクト
 * @param {boolean} props.isCompleted - 目標が完了しているかどうか
 * @param {Function} props.onEditClick - 編集ボタンクリック時のコールバック
 * @param {Function} props.onRemoveClick - 削除ボタンクリック時のコールバック
 * @param {Function} props.onCompleteClick - 完了ボタンクリック時のコールバック
 * @param {Function} props.onRevertClick - 元に戻すボタンクリック時のコールバック
 * @param {boolean} props.hideDetailLink - 詳細リンクを非表示にするかどうか
 * @param {boolean} props.showFullDetails - フル詳細を表示するかどうか
 * @param {Function} props.onReflectionClick - 振り返り追加ボタンクリック時のコールバック
 * @param {Function} props.onNavigate - ナビゲーション用コールバック
 * @param {Function} props.onStatusChange - ステータス変更用コールバック
 * @returns {React.Component} 目標カードコンポーネント
 */
const GoalCard = ({ goal, isCompleted, onEditClick, onRemoveClick, onCompleteClick, onRevertClick, hideDetailLink, showFullDetails, onReflectionClick, onStatusChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showChildGoals, setShowChildGoals] = React.useState(false);
  const targetValue = parseFloat(goal.target_value) || 0;
  const totalActualValue = goal.totalActualValue || 0;

  const toggleChildGoals = () => {
    setShowChildGoals(!showChildGoals);
  };

  // ステータスに基づいてボタンの表示を制御
  const renderStatusToggle = () => {
    if (isCompleted) return null; // 完了時は表示しない

    return (
      <Tooltip title={goal.status === 'not_started' ? '進行中にする' : '未着手に戻す'}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onStatusChange(goal, goal.status === 'not_started' ? 'in_progress' : 'not_started');
          }}
          size="small"
          sx={{ 
            mr: 1,
            bgcolor: goal.status === 'in_progress' ? 'action.selected' : 'transparent',
          }}
        >
          {goal.status === 'not_started' ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  // onNavigateを内部で定義
  const handleNavigate = (goalId) => {
    // 現在のパスを維持したまま、goalパラメータを追加
    navigate(`${location.pathname}?goal=${goalId}`);
  };

  return (
    <Card>
      <CardContent>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mb: 2,
            backgroundColor: '#0131b4',
            '&:hover': {
              backgroundColor: '#012590',
            },
          }}
          startIcon={<AddIcon />}
          onClick={() => onReflectionClick(goal)}
        >
          振り返りを追加
        </Button>
        <Box>
          <Typography variant="h6" component="div">
            {goal.title}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <MarkdownRenderer>
              {goal.description}
            </MarkdownRenderer>
          </Box>
          <Box sx={{ mt: 2 }}>
            {targetValue > 0 && goal.unit_name && (
              <>
                <Typography variant="body2">
                  目標数値: {formatNumber(targetValue)} {goal.unit_name}
                </Typography>
                {showFullDetails && (
                  <Typography variant="body2">
                    現在の実績: {formatNumber(totalActualValue)} {goal.unit_name}
                  </Typography>
                )}
                <GoalProgress goal={goal} />
              </>
            )}
            {showFullDetails && (
              <>
                <Typography variant="body2">
                  {isCompleted ? '完了日' : '期限'}: {formatDate(isCompleted ? goal.complete_date : goal.deadline)}
                </Typography>
                <Typography variant="body2">
                  振り返り数: {goal.reflectionCount}
                </Typography>
                <Typography variant="body2">
                  実施時間: {Math.floor(goal.totalDuration / 60)} 時間 {goal.totalDuration % 60} 分
                </Typography>
              </>
            )}
            <ReferenceLinks links={goal.reference_links} />
            {goal.parent_goal && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                親目標: 
                <Button
                  onClick={() => handleNavigate(goal.parent_goal.id)}
                  sx={{ ml: 1, cursor: 'pointer', textTransform: 'none' }}
                >
                  {goal.parent_goal.title}
                </Button>
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {!hideDetailLink && (
            <Button
              onClick={() => handleNavigate(goal.id)}
              sx={{ color: '#007bff', textTransform: 'none' }}
            >
              目標詳細を表示 →
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {renderStatusToggle()} {/* ステータストグルボタンを追加 */}
          <Button size="small" color="error" variant="outlined" sx={{ mr: 1 }} onClick={(e) => {
            e.stopPropagation();
            onRemoveClick(goal);
          }}>削除</Button>
          <Button size="small" color="primary" variant="outlined" sx={{ mr: 1 }} onClick={(e) => {
            e.stopPropagation();
            onEditClick(goal);
          }}>編集</Button>
          {isCompleted ? (
            <Button size="small" color="primary" variant="outlined" onClick={(e) => {
              e.stopPropagation();
              onRevertClick(goal);
            }}>元に戻す</Button>
          ) : (
            <Button size="small" color="success" variant="contained" onClick={(e) => {
              e.stopPropagation();
              onCompleteClick(goal);
            }}>完了</Button>
          )}
        </Box>
        {/* 子目標の表示 */}
        {goal.child_goals && goal.child_goals.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={toggleChildGoals}
              startIcon={showChildGoals ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              子目標 ({goal.child_goals.length})
            </Button>
            <Collapse in={showChildGoals}>
              <List dense>
                {goal.child_goals.map((childGoal) => (
                  <ListItem key={childGoal.id}>
                    <ListItemText
                      primary={childGoal.title}
                    />
                    <Button
                      onClick={() => handleNavigate(childGoal.id)}
                      size="small"
                    >
                      詳細
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default GoalCard;
