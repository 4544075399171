/**
 * @fileoverview 要約データを管理する共通カスタムフック
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';

/**
 * 要約データを管理するカスタムフック
 * @param {string} targetId - 対象のID
 * @param {Object} summaryService - 要約サービス
 * @param {boolean} [useTargetIdAsUserId=false] - targetIdをユーザーIDとして使用するかどうか
 * @returns {Object} 要約データと操作関数を含むオブジェクト
 */
export const useSummary = (targetId, summaryService, useTargetIdAsUserId = false) => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  const fetchSummaries = async () => {
    setLoading(true);
    try {
      // プロフィール要約の場合の処理
      let actualTargetId = targetId;
      if (summaryService.tableName === 'profile_summaries') {
        actualTargetId = useTargetIdAsUserId ? targetId : userId;
      }
      if (!actualTargetId) return;

      const data = await summaryService.fetchSummaries(actualTargetId);
      setSummaries(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createSummary = async (content, model, userInstructions, targetUserId = null) => {
    try {
      // プロフィール要約の場合の処理
      let actualTargetId = targetId;
      if (summaryService.tableName === 'profile_summaries') {
        actualTargetId = useTargetIdAsUserId ? targetUserId : userId;
      }
      if (!actualTargetId) throw new Error('ターゲットIDが見つかりません');

      await summaryService.createSummary({
        targetId: actualTargetId,
        content,
        model,
        userInstructions
      });
      await fetchSummaries();
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  useEffect(() => {
    if (summaryService.tableName === 'profile_summaries') {
      if (useTargetIdAsUserId ? targetId : userId) {
        fetchSummaries();
      }
    } else if (targetId) {
      fetchSummaries();
    }
  }, [targetId, userId, useTargetIdAsUserId]);

  return {
    summaries,
    loading,
    error,
    createSummary,
    fetchSummaries
  };
};
