/**
 * @file ForgotPasswordPage.js
 * @description パスワードリセットページのコンポーネント
 */

import React, { useState } from 'react';
import { 
  Typography, 
  Container, 
  Box, 
  TextField, 
  Button, 
  Alert, 
  CircularProgress,
  Link as MuiLink
} from '@mui/material';
import { Link } from 'react-router-dom';
import { supabase } from '../utils/supabase';

/**
 * パスワードリセットページ
 * @returns {JSX.Element} パスワードリセットフォームを表示するコンポーネント
 */
const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * フォーム送信時の処理
   * @param {Event} e - フォームのイベントオブジェクト
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;
      setIsSuccess(true);
    } catch (error) {
      setError(error.message === 'User not found'
        ? 'このメールアドレスは登録されていません。'
        : 'パスワードリセットメールの送信に失敗しました。時間をおいて再度お試しください。'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ 
        mt: 8, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
      }}>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          パスワードをお忘れの方
        </Typography>

        {isSuccess ? (
          <Box sx={{ textAlign: 'center' }}>
            <Alert severity="success" sx={{ mb: 3 }}>
              パスワードリセットのメールを送信しました。
              メールの内容に従って、パスワードの再設定を行ってください。
            </Alert>
            <MuiLink component={Link} to="/login" variant="body2">
              ログインページに戻る
            </MuiLink>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Typography variant="body2" sx={{ mb: 2 }}>
              登録時のメールアドレスを入力してください。
              パスワードリセット用のメールをお送りします。
            </Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="メールアドレス"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              error={!!error}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ 
                mt: 3, 
                mb: 2,
                bgcolor: '#0131b4',
                '&:hover': {
                  bgcolor: '#1565c0',
                },
                fontSize: '1.1rem',
                height: '50px'
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'パスワードリセットメールを送信'
              )}
            </Button>

            <Box sx={{ textAlign: 'center' }}>
              <MuiLink component={Link} to="/login" variant="body2">
                ログインページに戻る
              </MuiLink>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage; 