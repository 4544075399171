/**
 * @fileoverview 目標詳細チャットボックスコンポーネント
 * @description 特定の目標に関するチャットを行うコンポーネント
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  Avatar,
  IconButton,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import ReactMarkdown from 'react-markdown';
import { useGoalDetailChat } from '../../hooks/useGoalDetailChat';
import { goalDetailChatService } from '../../services/goalDetailChatService';
import { openaiService } from '../../services/openaiService';

const GoalDetailChatBox = ({ goal, reflections, onTasksGenerated }) => {
  const [input, setInput] = useState('');
  const [localError, setLocalError] = useState(null);
  const [isGeneratingTasks, setIsGeneratingTasks] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);

  const {
    messages,
    loading,
    error: chatError,
    model,
    chatHistories,
    currentHistoryId,
    shouldScrollRef,
    sendMessage,
    changeModel,
    switchChatHistory,
    createNewChat,
    setMessages,
    setError,
    setLoading,
    setChatHistories,
    setCurrentHistoryId
  } = useGoalDetailChat(goal?.id, goal, reflections);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || loading || isGeneratingTasks) return;

    try {
      await sendMessage(input);
      setInput('');
    } catch (error) {
      console.error('Error:', error);
      setLocalError('メッセージの送信に失敗しました');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleHistoryClick = (historyId) => {
    switchChatHistory(historyId);
    setIsHistoryDrawerOpen(false);
  };

  const handleNewChat = () => {
    createNewChat();
    setIsHistoryDrawerOpen(false);
  };

  const handleGenerateTasks = async () => {
    if (isGeneratingTasks) return;

    try {
      setIsGeneratingTasks(true);
      setLocalError(null);

      // タスク生成
      const tasks = await openaiService.generateTasksFromChat(messages, goal);
      
      // 生成結果を表示
      const aiMessage = {
        content: `
以下のタスクを生成しました：

${tasks.map(task => `- ${task.content}`).join('\n')}

タスクを追加しました。タスク一覧で確認してみましょう！`,
        isUser: false,
        timestamp: new Date().toISOString()
      };

      // メッセージを更新
      const updatedMessages = [...messages, aiMessage];
      setMessages(updatedMessages);

      // チャット履歴を更新
      if (currentHistoryId) {
        await goalDetailChatService.updateChatHistory(currentHistoryId, updatedMessages);
      } else {
        const newHistory = await goalDetailChatService.createChatHistory(goal.id, updatedMessages);
        setCurrentHistoryId(newHistory.id);
        setChatHistories(prev => [newHistory, ...prev]);
      }

      // 親コンポーネントにタスクを渡す
      if (onTasksGenerated) {
        onTasksGenerated(tasks);
      }

    } catch (error) {
      console.error('タスクの生成に失敗しました:', error);
      setLocalError('タスクの生成に失敗しました。もう少し具体的な相談をしてみましょう。');
    } finally {
      setIsGeneratingTasks(false);
    }
  };

  const messageStyle = (isUser) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: 2,
    mb: 2,
    flexDirection: isUser ? 'row-reverse' : 'row',
  });

  const bubbleStyle = (isUser) => ({
    maxWidth: '70%',
    p: 2,
    borderRadius: 2,
    bgcolor: isUser ? 'primary.main' : 'grey.100',
    color: isUser ? 'white' : 'text.primary',
    '& p': { m: 0 },
  });

  return (
    <Box sx={{ 
      height: { xs: 'calc(100vh - 300px)', sm: '400px' },
      display: 'flex', 
      flexDirection: 'column',
      borderRadius: 1,
      bgcolor: 'background.paper',
      position: 'relative'
    }}>
      {/* ヘッダー */}
      <Box sx={{ 
        p: { xs: 1.5, sm: 2 }, 
        borderBottom: 1, 
        borderColor: 'divider' 
      }}>
        <Typography variant="h6" component="div">
          目標達成のサポート
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between', 
          alignItems: { xs: 'stretch', sm: 'center' }, 
          mt: 2,
          gap: { xs: 1, sm: 2 }
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            width: { xs: '100%', sm: 'auto' }
          }}>
            <Button
              variant="contained"
              onClick={handleGenerateTasks}
              disabled={loading || isGeneratingTasks}
              startIcon={isGeneratingTasks ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <AutoAwesomeIcon />
              )}
              sx={{ 
                minHeight: { xs: '48px', sm: '36px' },
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              {isGeneratingTasks ? 'タスク生成中...' : 'タスクを生成'}
            </Button>
            <FormControl 
              size="small" 
              sx={{ 
                minWidth: { xs: '100%', sm: 200 },
                '& .MuiInputBase-root': {
                  minHeight: { xs: '48px', sm: '36px' }
                }
              }}
            >
              <InputLabel>AIモデル</InputLabel>
              <Select
                value={model}
                label="AIモデル"
                onChange={(e) => changeModel(e.target.value)}
                disabled={loading || isGeneratingTasks}
              >
                <MenuItem value="gpt-4">GPT-4</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
            width: { xs: '100%', sm: 'auto' },
            mt: { xs: 1, sm: 0 },
            gap: { xs: 0, sm: 1 }
          }}>
            <Tooltip title="新しいチャット">
              <IconButton 
                onClick={handleNewChat}
                sx={{ 
                  width: { xs: '48px', sm: '40px' },
                  height: { xs: '48px', sm: '40px' }
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="チャット履歴">
              <IconButton 
                onClick={() => setIsHistoryDrawerOpen(true)}
                sx={{ 
                  width: { xs: '48px', sm: '40px' },
                  height: { xs: '48px', sm: '40px' }
                }}
              >
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* メッセージエリア */}
      <Box sx={{ 
        flex: 1, 
        overflowY: 'auto', 
        p: { xs: 1.5, sm: 2 },
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: 1.5, sm: 2 }
      }}>
        {messages.map((message, index) => (
          <Box key={index} sx={messageStyle(message.isUser)}>
            {!message.isUser && (
              <Avatar
                alt="リフレコ"
                src="/images/refreco-avatar.png"
                sx={{ 
                  width: { xs: 32, sm: 40 }, 
                  height: { xs: 32, sm: 40 }
                }}
              />
            )}
            <Box sx={{
              ...bubbleStyle(message.isUser),
              maxWidth: { xs: '85%', sm: '70%' },
              p: { xs: 1.5, sm: 2 }
            }}>
              <ReactMarkdown>{message.content}</ReactMarkdown>
            </Box>
          </Box>
        ))}
      </Box>

      {/* 入力エリア */}
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          p: { xs: 1.5, sm: 2 }, 
          borderTop: 1, 
          borderColor: 'divider',
          backgroundColor: 'background.paper'
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            size="small"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            placeholder="目標について相談してください... (Shift + Enter で改行)"
            disabled={loading || isGeneratingTasks}
            sx={{
              '& .MuiInputBase-root': {
                padding: { xs: '12px', sm: '8px 14px' },
                minHeight: { xs: '48px', sm: '40px' }
              },
            }}
          />
          <IconButton 
            color="primary" 
            type="submit" 
            disabled={loading || isGeneratingTasks || !input.trim()}
            sx={{ 
              width: { xs: '48px', sm: '40px' },
              height: { xs: '48px', sm: '40px' }
            }}
          >
            {loading ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Box>
        {(localError || chatError) && (
          <Typography 
            color="error" 
            variant="caption" 
            sx={{ 
              mt: 1,
              display: 'block',
              px: 1
            }}
          >
            {localError || chatError}
          </Typography>
        )}
      </Box>

      {/* チャット履歴ドロワー */}
      <Drawer
        anchor="right"
        open={isHistoryDrawerOpen}
        onClose={() => setIsHistoryDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 250 }
          }
        }}
      >
        <Box sx={{ width: '100%' }} role="presentation">
          <List>
            {chatHistories
              .filter(history => history.messages.length > 0)
              .map((history) => (
                <ListItem 
                  button 
                  key={history.id} 
                  onClick={() => handleHistoryClick(history.id)}
                  selected={history.id === currentHistoryId}
                  sx={{
                    py: { xs: 2, sm: 1 }
                  }}
                >
                  <ListItemText 
                    primary={history.title || '無題のチャット'}
                    secondary={`${new Date(history.created_at).toLocaleDateString()} - ${history.messages.length}件のメッセージ`}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default GoalDetailChatBox; 