import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  List,
  Paper,
  FormControlLabel,
  Switch,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ja from 'date-fns/locale/ja';
import AddIcon from '@mui/icons-material/Add';
import { useTaskData } from '../../hooks/useTaskData';
import TaskItem from './TaskItem';
import TaskProgress from './TaskProgress';

const TaskList = ({ goalId, onTaskComplete, refreshTasks }) => {
  const {
    tasks,
    loading,
    error,
    handleAddTask,
    handleEditTask,
    handleToggleTask,
    handleDeleteTask,
    userId,
    fetchTasks
  } = useTaskData(goalId);

  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [sortBy, setSortBy] = useState('created_at');
  const [prevRefreshTasks, setPrevRefreshTasks] = useState(refreshTasks);
  const [newTaskDetails, setNewTaskDetails] = useState({
    content: '',
    priority: 'medium',
    dueDate: null,
  });
  const [isCreateFormExpanded, setIsCreateFormExpanded] = useState(false);

  useEffect(() => {
    if (refreshTasks !== prevRefreshTasks) {
      fetchTasks();
      setPrevRefreshTasks(refreshTasks);
    }
  }, [refreshTasks, prevRefreshTasks, fetchTasks]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newTaskDetails.content.trim()) {
      await handleAddTask(
        newTaskDetails.content.trim(),
        newTaskDetails.priority,
        newTaskDetails.dueDate
      );
      setNewTaskDetails({
        content: '',
        priority: 'medium',
        dueDate: null,
      });
      setIsCreateFormExpanded(false);
    }
  };

  const handleTaskToggle = async (taskId, isCompleted, content) => {
    const success = await handleToggleTask(taskId, isCompleted);
    if (success && isCompleted) {
      onTaskComplete(content);
    }
  };

  const handleTaskEdit = async (taskId, content, details = {}) => {
    await handleEditTask(taskId, content, details);
  };

  const sortTasks = (tasksToSort) => {
    return [...tasksToSort].sort((a, b) => {
      switch (sortBy) {
        case 'priority':
          const priorityOrder = { high: 0, medium: 1, low: 2 };
          return (priorityOrder[a.priority] || 1) - (priorityOrder[b.priority] || 1);
        case 'due_date':
          if (!a.due_date) return 1;
          if (!b.due_date) return -1;
          return new Date(a.due_date) - new Date(b.due_date);
        default:
          return new Date(b.created_at) - new Date(a.created_at);
      }
    });
  };

  const filteredTasks = tasks.filter(task => !task.is_completed || showCompletedTasks);
  const sortedTasks = sortTasks(filteredTasks);
  const completedTasksCount = tasks.filter(task => task.is_completed).length;

  if (loading) return <Typography>読み込み中...</Typography>;
  if (error) return <Typography color="error">エラー: {error}</Typography>;

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          タスク一覧
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>並び替え</InputLabel>
            <Select
              value={sortBy}
              label="並び替え"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <MenuItem value="created_at">作成日時</MenuItem>
              <MenuItem value="priority">優先度</MenuItem>
              <MenuItem value="due_date">期限</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={showCompletedTasks}
                onChange={(e) => setShowCompletedTasks(e.target.checked)}
                color="primary"
              />
            }
            label={`完了済み（${completedTasksCount}件）`}
          />
        </Box>
      </Box>

      <TaskProgress tasks={tasks} />
      
      <Divider sx={{ my: 2 }} />
      
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                size="small"
                value={newTaskDetails.content}
                onChange={(e) => setNewTaskDetails({ ...newTaskDetails, content: e.target.value })}
                placeholder="新しいタスクを追加"
                onFocus={() => setIsCreateFormExpanded(true)}
              />
              <Button
                variant="contained"
                type="submit"
                startIcon={<AddIcon />}
                sx={{ minWidth: '100px' }}
              >
                追加
              </Button>
            </Box>
          </Grid>
          {isCreateFormExpanded && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>優先度</InputLabel>
                  <Select
                    value={newTaskDetails.priority}
                    label="優先度"
                    onChange={(e) =>
                      setNewTaskDetails({ ...newTaskDetails, priority: e.target.value })
                    }
                  >
                    <MenuItem value="high">高</MenuItem>
                    <MenuItem value="medium">中</MenuItem>
                    <MenuItem value="low">低</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DateTimePicker
                    label="期限"
                    value={newTaskDetails.dueDate}
                    onChange={(newValue) =>
                      setNewTaskDetails({ ...newTaskDetails, dueDate: newValue })
                    }
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        size: "small"
                      } 
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <List>
        {sortedTasks.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            onToggle={handleTaskToggle}
            onEdit={handleTaskEdit}
            onDelete={handleDeleteTask}
            canEdit={task.user_id === userId}
          />
        ))}
      </List>
    </Paper>
  );
};

export default TaskList;