/**
 * @fileoverview 要約サービス基底クラス
 */

import { supabase } from '../utils/supabase';

export class BaseSummaryService {
  constructor(tableName, idField) {
    this.tableName = tableName;
    this.idField = idField;
  }

  /**
   * 要約を取得する
   * @param {string} targetId - 対象のID（goalIdまたはuserId）
   * @returns {Promise<Array>} 要約の配列
   */
  async fetchSummaries(targetId) {
    const query = supabase
      .from(this.tableName)
      .select(`
        id,
        created_at,
        ${this.idField},
        content,
        model,
        updated_at,
        user_instructions,
        user_id
      `)
      .eq(this.idField, targetId)
      .order('created_at', { ascending: false });

    // プロフィール要約の場合、対象ユーザーの要約のみを取得
    if (this.tableName === 'profile_summaries') {
      query.eq('user_id', targetId);
    }

    const { data, error } = await query;

    if (error) {
      console.error('要約の取得に失敗しました:', error);
      throw error;
    }

    return data;
  }

  /**
   * 新しい要約を作成する
   * @param {Object} summaryData - 要約データ
   * @returns {Promise<Object>} 作成された要約
   */
  async createSummary(summaryData) {
    // 現在のユーザーIDを取得
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('ユーザーが認証されていません');
    }

    const insertData = {
      [this.idField]: summaryData.targetId,
      content: summaryData.content,
      model: summaryData.model,
      user_instructions: summaryData.userInstructions,
      user_id: user.id // user_idを設定
    };

    // プロフィール要約の場合、対象ユーザーIDを設定
    if (this.tableName === 'profile_summaries') {
      insertData.user_id = summaryData.targetId;
    }

    const { data, error } = await supabase
      .from(this.tableName)
      .insert(insertData)
      .select(`
        id,
        created_at,
        ${this.idField},
        content,
        model,
        updated_at,
        user_id
      `);

    if (error) {
      console.error('要約の作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  }
}

export const goalSummaryService = new BaseSummaryService('goal_summaries', 'goal_id');
export const profileSummaryService = new BaseSummaryService('profile_summaries', 'user_id');
