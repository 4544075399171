import React, { useMemo, useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, Card, CardContent } from '@mui/material';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import ReflectionSkillPieChart from '../components/charts/ReflectionSkillPieChart';
import ReflectionTimeSeriesChart from '../components/charts/ReflectionTimeSeriesChart';
import GoalProgress from '../components/common/GoalProgress';
import { formatNumber } from '../utils/commonUtils';
import AccumulatedProgressCard from '../components/profile/AccumulatedProgressCard';
import ReactMarkdown from 'react-markdown';
import RecentReflections from '../components/reflections/RecentReflections';
import { useProjectData } from '../hooks/useProjectData';
import ProfileSummaryGenerator from '../components/profile/ProfileSummaryGenerator';
import { useAuth } from '../hooks/useAuth';

/**
 * プロフィールページコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} [props.targetUserId] - 表示対象のユーザーID（指定がない場合は現在のユーザー）
 * @param {boolean} [props.isViewingOther] - 他のユーザーのプロフィールを表示しているかどうか
 * @param {Object} [props.userProfile] - 表示対象のユーザープロフィール情報
 */
const ProfilePage = ({ targetUserId, isViewingOther = false, userProfile }) => {
  const { user } = useAuth();
  const effectiveTargetUserId = targetUserId || user?.id;
  const { goals, completedGoals } = useGoalData(null, null, effectiveTargetUserId);
  const { reflections } = useReflectionData(null, null, effectiveTargetUserId);
  const [goalTotals, setGoalTotals] = useState({});
  const [accumulatedValues, setAccumulatedValues] = useState({});
  const { projects } = useProjectData(effectiveTargetUserId);

  const allGoals = useMemo(() => [...goals, ...completedGoals], [goals, completedGoals]);

  useEffect(() => {
    const calculateGoalTotals = () => {
      const totals = allGoals.reduce((acc, goal) => {
        const unitId = goal.unit_id;
        const targetValue = parseFloat(goal.target_value) || 0;
        if (!acc[unitId]) {
          acc[unitId] = 0;
        }
        acc[unitId] += targetValue;
        return acc;
      }, {});
      setGoalTotals(totals);
    };

    calculateGoalTotals();
  }, [allGoals]);

  useEffect(() => {
    const calculateAccumulatedValues = () => {
      const accumulatedData = reflections.reduce((acc, reflection) => {
        const unitId = reflection.goals?.unit_id;
        const unitName = reflection.goals?.units?.name || '不明な単位';
        const actualValue = parseFloat(reflection.actual_value) || 0;

        if (unitId) {
          if (!acc[unitId]) {
            acc[unitId] = { unitName, total: 0 };
          }
          acc[unitId].total += actualValue;
        }
        return acc;
      }, {});

      setAccumulatedValues(accumulatedData);
    };

    calculateAccumulatedValues();
  }, [reflections]);

  const calculateGoalCompletionRate = () => {
    const totalGoals = goals.length + completedGoals.length;
    return totalGoals > 0 ? (completedGoals.length / totalGoals * 100).toFixed(2) : 0;
  };

  const calculateTotalReflectionTime = () => {
    return reflections.reduce((sum, reflection) => sum + (reflection.reflection_duration || 0), 0);
  };

  const totalReflectionTime = calculateTotalReflectionTime();

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {isViewingOther ? `${userProfile?.display_name || 'ユーザー'}のプロフィール` : 'プロフィール'}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AccumulatedProgressCard 
            accumulatedValues={accumulatedValues}
            goalTotals={goalTotals}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">目標達成状況</Typography>
            <Typography>総目標数: {goals.length + completedGoals.length}</Typography>
            <Typography>達成済み目標: {completedGoals.length}</Typography>
            <Typography>目標達成率: {calculateGoalCompletionRate()}%</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">振り返り統計</Typography>
            <Typography>総振り返り回数: {reflections.length}</Typography>
            <Typography>総振り返り時間: {Math.round(totalReflectionTime / 60)} 時間</Typography>
            <Typography>平均振り返り時間: {(totalReflectionTime / reflections.length / 60).toFixed(2)} 時間</Typography>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <ProfileSummaryGenerator 
            goals={goals}
            completedGoals={completedGoals}
            reflections={reflections}
            accumulatedValues={accumulatedValues}
            targetUserId={effectiveTargetUserId}
            isViewingOther={isViewingOther}
          />
        </Grid>

        <Grid item xs={12}>
          <RecentReflections 
            reflections={reflections} 
            projects={projects}
            targetUserId={effectiveTargetUserId}
            isViewingOther={isViewingOther}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <ReflectionSkillPieChart 
              targetUserId={effectiveTargetUserId}
              isViewingOther={isViewingOther}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <ReflectionTimeSeriesChart 
              targetUserId={effectiveTargetUserId}
              isViewingOther={isViewingOther}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePage;
