/**
 * @fileoverview 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @module RecentReflections
 */

import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Paper, Card, CardContent, Button, Box, Grid, Chip, Select, MenuItem, FormControl, InputLabel, styled, useMediaQuery, useTheme, Avatar, IconButton, Menu, ListItemIcon, ListItemText } from '@mui/material';
import { ReferenceLinks } from '../../utils/commonUtils';
import { useProjectData } from '../../hooks/useProjectData';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useReflectionData } from '../../hooks/useReflectionData';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReflectionDialog from './ReflectionDialog';
import ReflectionRemoveDialog from './ReflectionRemoveDialog';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link';
import MarkdownRenderer from '../common/MarkdownRenderer';

/**
 * スタイル付きのカードコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

/**
 * スタイル付きのアバターコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

/**
 * 個々の振り返りカードを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.reflection - 振り返りデータ
 * @param {Function} props.onEdit - 編集ハンドラー
 * @param {Function} props.onDelete - 削除ハンドラー
 * @param {boolean} props.isViewingOther - 他のユーザーの振り返りを表示しているかどうか
 * @returns {JSX.Element} 振り返りカードのJSX
 */
const ReflectionCard = ({ reflection, onEdit, onDelete, isViewingOther = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const goalTitle = reflection.goals?.title || '目標なし';
  const goalUnit = reflection.goals?.units?.name || '';

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(reflection);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDelete(reflection);
    handleMenuClose();
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const ContentBox = ({ title, content, icon }) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        {icon} {title}:
      </Typography>
      <Box sx={{ 
        mt: 0.5, 
        maxHeight: isExpanded ? 'none' : '100px',
        overflow: 'hidden',
        position: 'relative'
      }}>
        <MarkdownRenderer>
          {content}
        </MarkdownRenderer>
        {!isExpanded && content.length > 150 && (
          <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50px',
            background: 'linear-gradient(transparent, white)',
          }} />
        )}
      </Box>
    </Box>
  );

  return (
    <StyledCard elevation={2}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledAvatar>
              {goalTitle.charAt(0).toUpperCase()}
            </StyledAvatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {goalTitle}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {new Date(reflection.date).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          {!isViewingOther && (
            <>
              <IconButton size="small" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="編集" />
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="削除" />
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {formatTime(reflection.start_time)} ~ {formatTime(reflection.end_time)}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            実績: {reflection.actual_value ? `${reflection.actual_value} ${goalUnit}` : '-'}
          </Typography>
        </Box>

        <ContentBox 
          title="達成したこと"
          content={reflection.achievement}
          icon={<EmojiEventsIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        <ContentBox 
          title="学んだこと"
          content={reflection.learning}
          icon={<EmojiObjectsIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        <ContentBox 
          title="次にやること・改善点"
          content={reflection.improvement}
          icon={<TrendingUpIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        {reflection.artifacts && reflection.artifacts.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <WorkIcon fontSize="small" sx={{ mr: 1 }} /> 成果物:
            </Typography>
            <ReferenceLinks links={reflection.artifacts} />
          </Box>
        )}

        {reflection.reference_links && reflection.reference_links.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <LinkIcon fontSize="small" sx={{ mr: 1 }} /> 参考リンク:
            </Typography>
            <ReferenceLinks links={reflection.reference_links} />
          </Box>
        )}

        {(reflection.achievement.length > 150 ||
          reflection.learning.length > 150 ||
          reflection.improvement.length > 150) && (
          <Button 
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ mb: 2 }}
          >
            {isExpanded ? '閉じる' : 'さらに見る'}
          </Button>
        )}

        <Box sx={{ mt: 'auto' }}>
          {reflection.skills && reflection.skills.map((skill) => (
            <Chip key={skill.id} label={skill.name} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
          ))}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

/**
 * 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} [props.defaultProjectFilter] - デフォルトのプロジェクトフィルター
 * @param {string} [props.defaultGoalFilter] - デフォルトのゴールフィルター
 * @param {string} [props.targetUserId] - 表示対象のユーザーID
 * @param {boolean} [props.isViewingOther] - 他のユーザーの振り返りを表示しているかどうか
 * @returns {JSX.Element} 振り返り一覧のJSX
 */
const RecentReflections = ({ 
  defaultProjectFilter, 
  defaultGoalFilter, 
  refreshTrigger,
  targetUserId = null,
  isViewingOther = false
}) => {
  const { reflections, loading, error, fetchReflections, handleDeleteReflection } = useReflectionData(defaultGoalFilter, defaultProjectFilter, targetUserId);
  const { projects } = useProjectData(targetUserId);
  const [displayCount, setDisplayCount] = useState(4);
  const [projectFilter, setProjectFilter] = useState(defaultProjectFilter || '');
  const [goalFilter, setGoalFilter] = useState(defaultGoalFilter || '');
  const [skillFilter, setSkillFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [editingReflection, setEditingReflection] = useState(null);
  const [deletingReflection, setDeletingReflection] = useState(null);

  useEffect(() => {
    fetchReflections();
  }, [refreshTrigger]);

  /**
   * 表示する振り返りの数を増やす
   */
  const handleShowMore = () => {
    setDisplayCount(prevCount => prevCount + 8);
  };

  /**
   * プロジェクトオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const projectOptions = useMemo(() => {
    return projects.map(project => ({
      id: project.id,
      name: project.name
    }));
  }, [projects]);

  /**
   * ゴールオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const goalOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.id === id)?.goals?.title || 'Unknown'
    }));
  }, [reflections]);

  /**
   * スキルオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const skillOptions = useMemo(() => {
    const skills = reflections.flatMap(r => r.skills || []);
    return [...new Set(skills.map(s => s?.id).filter(Boolean))].map(id => ({
      id,
      name: skills.find(s => s?.id === id)?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * 単位オプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const unitOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.unit_id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.unit_id === id)?.goals?.units?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * フィルタリングされた振り返りを取得する
   * @type {Array<Object>}
   */
  const filteredReflections = useMemo(() => {
    return reflections.filter(reflection => 
      (!projectFilter || reflection.goals?.project_id === projectFilter) &&
      (!goalFilter || reflection.goals?.id === goalFilter) &&
      (!skillFilter || reflection.skills?.some(skill => skill?.id === skillFilter)) &&
      (!unitFilter || reflection.goals?.unit_id === unitFilter)
    );
  }, [reflections, projectFilter, goalFilter, skillFilter, unitFilter]);

  const handleEditReflection = (reflection) => {
    if (!isViewingOther) {
      setEditingReflection(reflection);
    }
  };

  const handleSaveReflection = async (updatedReflection) => {
    if (!isViewingOther) {
      await handleEditReflection(updatedReflection);
      setEditingReflection(null);
      fetchReflections();
    }
  };

  const handleDeleteClick = (reflection) => {
    if (!isViewingOther) {
      setDeletingReflection(reflection);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingReflection && !isViewingOther) {
      try {
        await handleDeleteReflection({ id: deletingReflection.id });
        setDeletingReflection(null);
        fetchReflections();
      } catch (error) {
        console.error('振り返りの削除に失敗しました:', error);
        alert('振り返りの削除中にエラーが発生しました。');
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>直近の振り返り</Typography>
      
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: 2,
        mb: 2,
        overflowX: isSmallScreen ? 'auto' : 'visible',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: '4px',
        },
      }}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>プロジェクト</InputLabel>
          <Select value={projectFilter} onChange={(e) => setProjectFilter(e.target.value)} label="プロジェクト">
            <MenuItem value="">全て</MenuItem>
            {projectOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>ゴール</InputLabel>
          <Select value={goalFilter} onChange={(e) => setGoalFilter(e.target.value)} label="ゴール">
            <MenuItem value="">全て</MenuItem>
            {goalOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>スキル</InputLabel>
          <Select value={skillFilter} onChange={(e) => setSkillFilter(e.target.value)} label="スキル">
            <MenuItem value="">全て</MenuItem>
            {skillOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>単位</InputLabel>
          <Select value={unitFilter} onChange={(e) => setUnitFilter(e.target.value)} label="単位">
            <MenuItem value="">全て</MenuItem>
            {unitOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {filteredReflections.slice(0, displayCount).map((reflection) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={reflection.id}>
            <ReflectionCard
              reflection={reflection}
              onEdit={handleEditReflection}
              onDelete={() => handleDeleteClick(reflection)}
              isViewingOther={isViewingOther}
            />
          </Grid>
        ))}
      </Grid>
      {displayCount < filteredReflections.length && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button variant="outlined" onClick={handleShowMore}>
            さらに表示する
          </Button>
        </Box>
      )}

      {!isViewingOther && editingReflection && (
        <ReflectionDialog
          goal={editingReflection.goals}
          reflection={editingReflection}
          onClose={() => setEditingReflection(null)}
          onSave={handleSaveReflection}
          open={Boolean(editingReflection)}
        />
      )}

      {!isViewingOther && deletingReflection && (
        <ReflectionRemoveDialog
          reflections={[deletingReflection.id]}
          onClose={() => setDeletingReflection(null)}
          onRemove={handleConfirmDelete}
          onActivityChange={fetchReflections}
        />
      )}
    </Paper>
  );
};

export default RecentReflections;
