/**
 * @file PublicHeader.js
 * @description 未ログイン時のヘッダーを表示するReactコンポーネント
 * 
 * このコンポーネントは、未ログイン時のヘッダーバーを実装します。
 * ログインと登録ボタンを表示し、ランディングページへのリンクを提供します。
 * 
 * @requires React
 * @requires react-router-dom
 * @requires @mui/material
 */

import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  useMediaQuery, 
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Link as MuiLink
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

/**
 * PublicHeaderは、未ログイン時のヘッダーバーを表示するReactコンポーネントです。
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} [props.hideAuthButtons=false] - 認証ボタンを非表示にするかどうか
 * @returns {JSX.Element} ヘッダーバーを表示するAppBar要素
 */
const PublicHeader = ({ hideAuthButtons = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    handleMenuClose();
  };

  const buttonStyle = {
    fontSize: { xs: '0.9rem', sm: '1.1rem' },
    padding: '8px 16px',
    minWidth: '100px',
    height: '50px',
  };

  const navigationLinks = [
    { label: '課題', id: 'challenges' },
    { label: '主要機能', id: 'features' },
    { label: '料金', id: 'pricing' },
  ];

  return (
    <AppBar position="fixed" color="transparent" elevation={0} sx={{ 
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      bgcolor: 'rgba(255, 255, 255, 0.95)',
      backdropFilter: 'blur(8px)'
    }}>
      <Toolbar sx={{ 
        flexDirection: isMobile ? 'column' : 'row', 
        py: isMobile ? 2 : 0,
        justifyContent: 'space-between'
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          width: isMobile ? '100%' : 'auto',
          justifyContent: isMobile ? 'space-between' : 'flex-start'
        }}>
          <Link to="/" style={{ 
            textDecoration: 'none', 
            color: 'inherit', 
            display: 'flex', 
            alignItems: 'center'
          }}>
            <img 
              src="/images/logo-public.png" 
              alt="RefReco Logo" 
              style={{ height: '50px', maxWidth: '200px', objectFit: 'contain' }} 
            />
          </Link>

          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Box>

        {/* デスクトップナビゲーション */}
        {!isMobile && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 3,
            mx: 4
          }}>
            {navigationLinks.map((link) => (
              <MuiLink
                key={link.id}
                component="button"
                onClick={() => scrollToSection(link.id)}
                sx={{
                  color: 'text.primary',
                  textDecoration: 'none',
                  fontSize: '1rem',
                  fontWeight: 500,
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                {link.label}
              </MuiLink>
            ))}
          </Box>
        )}

        {/* 認証ボタン */}
        {!hideAuthButtons && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              color="inherit" 
              component={Link}
              to="/login"
              sx={{ 
                ...buttonStyle,
                bgcolor: 'background.paper', 
                color: 'text.primary',
                '&:hover': {
                  bgcolor: 'background.default',
                },
                border: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.23)',
              }}
            >
              ログイン
            </Button>
            <Button 
              color="primary" 
              variant="contained"
              component={Link}
              to="/register"
              sx={{
                ...buttonStyle,
                bgcolor: '#0131b4',
                color: theme.palette.common.white,
                '&:hover': {
                  bgcolor: '#1565c0',
                },
                boxShadow: '0 3px 5px 2px rgba(25, 118, 210, .3)',
              }}
            >
              登録
            </Button>
          </Box>
        )}

        {/* モバイルメニュー */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              width: '200px',
              mt: 2
            }
          }}
        >
          {navigationLinks.map((link) => (
            <MenuItem 
              key={link.id}
              onClick={() => scrollToSection(link.id)}
              sx={{
                py: 1.5,
                px: 3
              }}
            >
              {link.label}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
