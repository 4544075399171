import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Chip,
  Stack,
  InputAdornment,
  Divider,
  Paper,
  Tooltip,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { useReflectionData } from '../../hooks/useReflectionData';
import { reflectionService } from '../../services/reflectionService';
import { skillService } from '../../services/skillService';
import { unitService } from '../../services/unitService';
import {
  setCurrentTime,
  handleSkillClick,
  handleAddLink,
  handleLinkChange,
  handleRemoveLink,
  updateReflectionTime
} from '../../utils/commonUtils';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReflectionTimer from './ReflectionTimer';

/**
 * 振り返りフォームを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 目標オブジェクト
 * @param {Object} [props.reflection] - 編集する振り返りオブジェクト（新規作成時は未定義）
 * @param {Function} props.onSave - 振り返りを保存する関数
 * @param {Function} props.onClose - フォームを閉じる関数
 * @param {boolean} props.isDialog - ダイアログ内で表示されているかどうか
 * @param {string} [props.initialAchievement] - 達成内容の初期値
 * @returns {React.ReactElement} 振り返りフォームのJSX
 */
const ReflectionForm = ({ 
  goal, 
  reflection, 
  onSave, 
  onClose, 
  isDialog,
  initialAchievement = ''
}) => {
  const { 
    userId, 
    reflections, 
    skills, 
    handleEditReflection, 
    handleAddSkill
  } = useReflectionData(goal ? goal.id : null);
  const [date, setDate] = useState(reflection ? new Date(reflection.date) : new Date());
  const [achievements, setAchievements] = useState(
    reflection ? reflection.achievement : initialAchievement
  );
  const [learnings, setLearnings] = useState(reflection ? reflection.learning : '');
  const [improvements, setImprovements] = useState(reflection ? reflection.improvement : '');
  const [startTime, setStartTime] = useState(reflection && reflection.start_time ? new Date(reflection.start_time) : null);
  const [endTime, setEndTime] = useState(reflection && reflection.end_time ? new Date(reflection.end_time) : null);
  const [reflectionTime, setReflectionTime] = useState(reflection && reflection.reflection_duration ? reflection.reflection_duration.toString() : '');
  const [selectedSkills, setSelectedSkills] = useState(reflection && reflection.skills ? reflection.skills.map(skill => skill.name) : []);
  const [newSkill, setNewSkill] = useState('');
  const [referenceLinks, setReferenceLinks] = useState(reflection && reflection.reference_links ? reflection.reference_links : [{ url: '', title: '' }]);
  const [actualValue, setActualValue] = useState(reflection && reflection.actual_value ? reflection.actual_value.toString() : '');
  const [unitName, setUnitName] = useState('');
  const [targetValue, setTargetValue] = useState(0);
  const [totalActualValue, setTotalActualValue] = useState(0);
  const [artifacts, setArtifacts] = useState(
    reflection && reflection.artifacts ? reflection.artifacts : [{ url: '', title: '' }]
  );

  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();

  useEffect(() => {
    const loadData = async () => {
      if (!userId) return;

      if (goal && goal.unit_id) {
        try {
          const unitData = await unitService.fetchUnitById(goal.unit_id);
          setUnitName(unitData.name);
        } catch (error) {
          console.error('単位の取得に失敗しました:', error);
          setUnitName('');
        }
        setTargetValue(parseFloat(goal.target_value) || 0);
        setTotalActualValue(parseFloat(goal.totalActualValue) || 0);
      } else {
        setUnitName('');
        setTargetValue(0);
        setTotalActualValue(0);
      }
    };
    loadData();
  }, [goal, userId]);

  useEffect(() => {
    updateReflectionTime(startTime, endTime, setReflectionTime);
  }, [startTime, endTime]);

  /**
   * 新しいスキルを追加する
   * @param {string} newSkillName - 追加するスキル名
   */
  const handleAddNewSkill = async (newSkillName) => {
    if (!newSkillName) return;

    try {
      // 既存のスキルかチェック
      const existingSkill = skills.find(skill => skill.name === newSkillName);
      
      if (existingSkill) {
        // 既存のスキルの場合は、選択済みに追加
        if (!selectedSkills.includes(newSkillName)) {
          setSelectedSkills(prevSelected => [...prevSelected, newSkillName]);
        }
      } else {
        // 新しいスキルの場合は、追加してから選択済みに追加
        const addedSkill = await handleAddSkill(newSkillName);
        setSelectedSkills(prevSelected => [...prevSelected, addedSkill.name]);
      }
      
      setNewSkill(''); // 入力フィールドをクリア
    } catch (error) {
      console.error('スキルの追加中にエラーが発生しました:', error);
    }
  };

  /**
   * フォームを送信する
   */
  const handleSubmit = async () => {
    try {
      if (!userId) {
        console.error('ユーザーが認証されていません');
        return;
      }

      const reflectionTimeInMinutes = reflectionTime ? parseInt(reflectionTime) : null;
      
      const combineDateAndTime = (baseDate, time) => {
        if (!time) return null;
        
        const combined = new Date(baseDate);
        combined.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

        // 終了時間が開始時間より前の場合（日付をまたぐ場合）
        if (startTime && time === endTime && time < startTime) {
          combined.setDate(combined.getDate() + 1);
        }
        
        return combined.toISOString();
      };

      const formattedStartTime = combineDateAndTime(date, startTime);
      const formattedEndTime = combineDateAndTime(date, endTime);

      // 日付をまたぐ場合の時間の妥当性チェック
      if (formattedStartTime && formattedEndTime) {
        const start = new Date(formattedStartTime);
        const end = new Date(formattedEndTime);
        if (end < start) {
          end.setDate(end.getDate() + 1);
          formattedEndTime = end.toISOString();
        }
      }

      const formattedSkills = selectedSkills.map(skillName => {
        const skill = skills.find(s => s.name === skillName);
        return skill ? {
          id: skill.id,
          created_at: skill.created_at,
          user_id: skill.user_id,
          name: skill.name
        } : { name: skillName };
      });

      const reflectionData = {
        user_id: userId,
        goal_id: goal.id,
        achievement: achievements,
        learning: learnings,
        improvement: improvements,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        reflection_duration: reflectionTimeInMinutes,
        skills: formattedSkills,
        date: date.toISOString(),
        reference_links: referenceLinks.filter(link => link.url),
        actual_value: actualValue ? parseFloat(actualValue) : null,
        artifacts: artifacts.filter(artifact => artifact.url),
      };

      if (reflection) {
        reflectionData.id = reflection.id;
        await handleEditReflection(reflectionData);
      } else {
        await reflectionService.createReflection(reflectionData);
      }

      onSave(reflectionData);
      onClose(); // ダイアログを閉じる
    } catch (error) {
      console.error('振り返りの保存中にエラーが発生しました:', error);
    }
  };

  /**
   * タイマーからの時間更新を処理する
   * @param {Object} timeData - タイマーデータ
   * @param {number} timeData.duration - 経過時間（分）
   * @param {Date} timeData.startTime - 開始時間
   * @param {Date} timeData.endTime - 終了時間
   */
  const handleTimeUpdate = (timeData) => {
    setReflectionTime(timeData.duration.toString());
    setStartTime(timeData.startTime);
    setEndTime(timeData.endTime);
  };

  // セクションスタイル
  const sectionStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 1,
    p: 2,
    mb: 3,
  };

  // セクションヘッダースタイル
  const sectionHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 2,
  };

  return (
    <Box>
      {/* 目標情報セクション */}
      <Paper sx={{ ...sectionStyle, backgroundColor: 'primary.main', color: 'white', mb: 3 }}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">目標</Typography>
          <Tooltip title="この振り返りの対象となる目標です">
            <HelpOutlineIcon fontSize="small" sx={{ color: 'white' }} />
          </Tooltip>
        </Box>
        <Typography variant="h5" gutterBottom>
          {goal.title}
        </Typography>
        {goal.target_value && goal.unit_name && (
          <Typography variant="body1">
            目標値: {goal.target_value} {goal.unit_name}
            {totalActualValue > 0 && ` (現在の実績: ${totalActualValue} ${goal.unit_name})`}
          </Typography>
        )}
      </Paper>

      {/* タイマーコンポーネントを追加 */}
      <ReflectionTimer onTimeUpdate={handleTimeUpdate} />

      {/* 基本情報セクション */}
      <Paper sx={sectionStyle}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">基本情報</Typography>
          <Tooltip title="振り返りの基本的な情報を入力してください">
            <HelpOutlineIcon fontSize="small" color="action" />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DatePicker
            label="日付"
            value={date}
            onChange={setDate}
            format="yyyy/MM/dd"
            slotProps={{ 
              textField: { 
                size: 'small',
                fullWidth: true
              }
            }}
          />
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TimePicker
              label="開始時間"
              value={startTime}
              onChange={setStartTime}
              format="HH:mm"
              slotProps={{ 
                textField: { 
                  size: 'small',
                  fullWidth: true,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setCurrentTime(setStartTime)}
                        >
                          <AccessTimeIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }
              }}
            />
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <TimePicker
              label="終了時間"
              value={endTime}
              onChange={setEndTime}
              format="HH:mm"
              slotProps={{ 
                textField: { 
                  size: 'small',
                  fullWidth: true,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setCurrentTime(setEndTime)}
                        >
                          <AccessTimeIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }
              }}
            />
          </Box>
        </Box>
        <TextField
          label="振り返り時間（分）"
          value={reflectionTime}
          onChange={(e) => setReflectionTime(e.target.value)}
          type="number"
          size="small"
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="達成値"
          value={actualValue}
          onChange={(e) => setActualValue(e.target.value)}
          type="number"
          size="small"
          fullWidth
          InputProps={{
            endAdornment: unitName ? (
              <InputAdornment position="end">{unitName}</InputAdornment>
            ) : null,
          }}
        />
      </Paper>

      {/* 振り返り内容セクション */}
      <Paper sx={sectionStyle}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">振り返り内容</Typography>
          <Tooltip title="達成したこと、学んだこと、改善点を記入してください">
            <HelpOutlineIcon fontSize="small" color="action" />
          </Tooltip>
        </Box>
        <Typography variant="subtitle1" gutterBottom>達成したこと</Typography>
        <Box sx={mdxStyles}>
          <MDXEditor
            markdown={achievements}
            onChange={setAchievements}
            plugins={mdxPlugins}
            contentEditableClassName="prose max-w-full"
          />
        </Box>
        
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>学んだこと</Typography>
        <Box sx={mdxStyles}>
          <MDXEditor
            markdown={learnings}
            onChange={setLearnings}
            plugins={mdxPlugins}
            contentEditableClassName="prose max-w-full"
          />
        </Box>
        
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>改善点・次にやること</Typography>
        <Box sx={mdxStyles}>
          <MDXEditor
            markdown={improvements}
            onChange={setImprovements}
            plugins={mdxPlugins}
            contentEditableClassName="prose max-w-full"
          />
        </Box>
      </Paper>

      {/* 成果物セクション */}
      <Paper sx={sectionStyle}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">成果物</Typography>
          <Tooltip title="作成した成果物のURLを追加してください">
            <HelpOutlineIcon fontSize="small" color="action" />
          </Tooltip>
        </Box>
        {artifacts.map((artifact, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <TextField
              label="URL"
              value={artifact.url}
              onChange={(e) => handleLinkChange(index, 'url', e.target.value, artifacts, setArtifacts)}
              size="small"
              fullWidth
              sx={{ mr: 1 }}
            />
            <TextField
              label="タイトル"
              value={artifact.title}
              onChange={(e) => handleLinkChange(index, 'title', e.target.value, artifacts, setArtifacts)}
              size="small"
              fullWidth
              sx={{ mr: 1 }}
            />
            <IconButton onClick={() => handleRemoveLink(index, artifacts, setArtifacts)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          startIcon={<LinkIcon />}
          onClick={() => handleAddLink(setArtifacts)}
          variant="outlined"
          size="small"
          fullWidth
        >
          成果物URLを追加
        </Button>
      </Paper>

      {/* スキルセクション */}
      <Paper sx={sectionStyle}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">関連スキル</Typography>
          <Tooltip title="この振り返りに関連するスキルを選択してください">
            <HelpOutlineIcon fontSize="small" color="action" />
          </Tooltip>
        </Box>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap mb={2}>
          {skills.map((skill) => (
            <Chip
              key={skill.id}
              label={skill.name}
              onClick={() => handleSkillClick(skill.name, selectedSkills, setSelectedSkills)}
              color={selectedSkills.includes(skill.name) ? "primary" : "default"}
              size="small"
            />
          ))}
        </Stack>
        <Box display="flex" gap={1}>
          <TextField
            label="新しいスキル"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            size="small"
            fullWidth
          />
          <Button
            onClick={() => handleAddNewSkill(newSkill)}
            variant="contained"
            size="small"
          >
            追加
          </Button>
        </Box>
      </Paper>

      {/* 参考リンクセクション */}
      <Paper sx={sectionStyle}>
        <Box sx={sectionHeaderStyle}>
          <Typography variant="h6">参考リンク</Typography>
          <Tooltip title="参考にした資料やWebサイトのURLを追加してください">
            <HelpOutlineIcon fontSize="small" color="action" />
          </Tooltip>
        </Box>
        {referenceLinks.map((link, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <TextField
              label="URL"
              value={link.url}
              onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
              size="small"
              fullWidth
              sx={{ mr: 1 }}
            />
            <TextField
              label="タイトル"
              value={link.title}
              onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
              size="small"
              fullWidth
              sx={{ mr: 1 }}
            />
            <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          startIcon={<LinkIcon />}
          onClick={() => handleAddLink(setReferenceLinks)}
          variant="outlined"
          size="small"
          fullWidth
        >
          参考リンクを追加
        </Button>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 4 }}>
        <Button onClick={onClose} sx={{ mr: 2 }}>
          キャンセル
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {reflection ? '更新' : '保存'}
        </Button>
      </Box>
    </Box>
  );
};

export default ReflectionForm;
