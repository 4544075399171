/**
 * @fileoverview 目標詳細チャットのカスタムフック
 * @description 目標詳細ページのチャット機能を管理するカスタムフック
 */

import { useState, useEffect, useRef, useCallback } from 'react';
import { goalDetailChatService } from '../services/goalDetailChatService';
import { openaiService } from '../services/openaiService';
import { supabase } from '../utils/supabase';

/**
 * 目標詳細チャットのカスタムフック
 * @param {number} goalId - 目標ID
 * @param {Object} goal - 目標オブジェクト
 * @param {Array} reflections - 振り返りの配列
 * @returns {Object} チャット関連の状態と関数
 */
export const useGoalDetailChat = (goalId, goal, reflections) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [model, setModel] = useState('gpt-4');
  const [chatHistories, setChatHistories] = useState([]);
  const [currentHistoryId, setCurrentHistoryId] = useState(null);
  const shouldScrollRef = useRef(true);

  // 初期メッセージの生成
  const generateInitialMessage = async () => {
    try {
      if (!goal) return;

      // 最新の情報を取得してメッセージを生成
      const message = await openaiService.generateGoalSupportMessage({
        goal: {
          id: goal.id,
          title: goal.title,
          description: goal.description,
          deadline: goal.deadline,
          is_completed: goal.is_completed,
          target_value: goal.target_value,
          actual_value: goal.actual_value,
          status: goal.status,
          unit_name: goal.unit_name
        },
        reflections: reflections || []
      });

      const aiMessage = {
        content: message,
        isUser: false,
        timestamp: new Date().toISOString()
      };

      return aiMessage;
    } catch (error) {
      console.error('初期メッセージの生成に失敗しました:', error);
      throw error;
    }
  };

  // チャット履歴の取得
  const fetchChatHistories = useCallback(async () => {
    if (!goalId) return;

    try {
      const histories = await goalDetailChatService.getChatHistories(goalId);
      setChatHistories(histories);

      // 最新の履歴があれば、それを現在の履歴として設定
      if (histories.length > 0) {
        const latestHistory = histories[0];
        setCurrentHistoryId(latestHistory.id);
        setMessages(latestHistory.messages || []);
        shouldScrollRef.current = true;
      } else if (goal) {
        // 履歴がない場合は初期メッセージを生成
        const initialMessage = await generateInitialMessage();
        if (initialMessage) {
          setMessages([initialMessage]);
          // 新しい履歴を作成
          const newHistory = await goalDetailChatService.createChatHistory(goalId, [initialMessage]);
          setChatHistories([newHistory]);
          setCurrentHistoryId(newHistory.id);
          shouldScrollRef.current = true;
        }
      }
    } catch (error) {
      console.error('チャット履歴の取得に失敗しました:', error);
      setError('チャット履歴の取得に失敗しました');
    }
  }, [goalId, goal, reflections]);

  // 初期データの取得
  useEffect(() => {
    if (goalId && goal) {
      fetchChatHistories();
    }
  }, [fetchChatHistories, goalId, goal]);

  // チャット履歴の切り替え
  const switchChatHistory = async (historyId) => {
    try {
      const history = chatHistories.find(h => h.id === historyId);
      if (history) {
        setCurrentHistoryId(historyId);
        setMessages(history.messages || []);
        shouldScrollRef.current = true;
      }
    } catch (error) {
      console.error('チャット履歴の切り替えに失敗しました:', error);
      setError('チャット履歴の切り替えに失敗しました');
    }
  };

  // 新しいチャットの作成
  const createNewChat = async () => {
    try {
      // 初期メッセージを生成
      const initialMessage = await generateInitialMessage();
      const initialMessages = initialMessage ? [initialMessage] : [];
      
      // 新しい履歴を作成
      const newHistory = await goalDetailChatService.createChatHistory(goalId, initialMessages);
      setChatHistories(prev => [newHistory, ...prev]);
      setCurrentHistoryId(newHistory.id);
      setMessages(initialMessages);
      shouldScrollRef.current = true;
    } catch (error) {
      console.error('新しいチャットの作成に失敗しました:', error);
      setError('新しいチャットの作成に失敗しました');
    }
  };

  // メッセージの送信
  const sendMessage = async (content) => {
    try {
      setLoading(true);
      setError(null);

      // ユーザーメッセージの作成
      const userMessage = {
        content,
        isUser: true,
        timestamp: new Date().toISOString()
      };

      // 現在のメッセージ配列を更新
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      shouldScrollRef.current = true;

      // チャット履歴がない場合は新規作成
      if (!currentHistoryId) {
        // 新規作成時は必ずタイトルを生成
        const newHistory = await goalDetailChatService.createChatHistory(goalId, updatedMessages, true);
        setCurrentHistoryId(newHistory.id);
        setChatHistories(prev => [newHistory, ...prev]);
      } else {
        // 既存の履歴を更新（ユーザーメッセージ送信時は必ずタイトルを更新）
        await goalDetailChatService.updateChatHistory(currentHistoryId, updatedMessages, true);
      }

      // AIの応答を取得
      const aiResponse = await goalDetailChatService.getAIResponse(updatedMessages, goal);
      const aiMessage = {
        content: aiResponse,
        isUser: false,
        timestamp: new Date().toISOString()
      };

      // AIの応答を含めた最終的なメッセージ配列を更新
      const finalMessages = [...updatedMessages, aiMessage];
      setMessages(finalMessages);
      shouldScrollRef.current = true;

      // チャット履歴を更新（AIの応答を含めた状態で再度タイトルを更新）
      await goalDetailChatService.updateChatHistory(currentHistoryId, finalMessages, true);

      // チャット履歴リストを更新
      await fetchChatHistories();

    } catch (error) {
      console.error('メッセージの送信に失敗しました:', error);
      setError('メッセージの送信に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  // モデルの変更
  const changeModel = (newModel) => {
    setModel(newModel);
  };

  return {
    messages,
    loading,
    error,
    model,
    chatHistories,
    currentHistoryId,
    shouldScrollRef,
    sendMessage,
    changeModel,
    switchChatHistory,
    createNewChat,
    setMessages,
    setError,
    setLoading
  };
}; 