import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  linkPlugin,
  linkDialogPlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
  Separator,
  codeBlockPlugin,
  imagePlugin,
  tablePlugin,

} from '@mdxeditor/editor';

/**
 * MDXEditorの共通プラグイン設定
 * @returns {Array} MDXEditorで使用するプラグインの配列
 */
export const getMDXEditorPlugins = () => [
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  linkPlugin(),
  linkDialogPlugin(),
  imagePlugin(),
  tablePlugin(),
  codeBlockPlugin(),
  toolbarPlugin({
    toolbarContents: () => (
      <>
        <UndoRedo />
        <Separator />
        <ListsToggle />
        <Separator />
        <BoldItalicUnderlineToggles />
      </>
    )
  })
];

/**
 * MDXEditorの共通スタイル設定
 * @returns {Object} MDXEditorのコンテナに適用するスタイルオブジェクト
 */
export const getMDXEditorStyles = () => ({
  border: '1px solid #ccc', 
  borderRadius: '4px', 
  mb: 2,
  minHeight: '200px',
  padding: '6px',
});

/**
 * MDXEditorのプレースホルダーテキスト
 */
export const EDITOR_PLACEHOLDER_TEXT = 'ここに入力してください...';
