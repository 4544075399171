/**
 * @fileoverview 振り返りデータを取得・管理するカスタムフック
 * @module useReflectionData
 * @requires react
 * @requires ../utils/supabase
 * @requires ../services/reflectionService
 * @requires ../services/skillService
 */

import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabase';
import { reflectionService } from '../services/reflectionService';
import { skillService } from '../services/skillService'; 

/**
 * 振り返りデータを取得・管理するカスタムフック
 * @function useReflectionData
 * @param {string|null} [goalId=null] - 目標ID
 * @param {string|null} [projectId=null] - プロジェクトID
 * @param {string|null} [targetUserId=null] - 表示対象のユーザーID
 * @returns {Object} 振り返りデータ、スキルデータ、ローディング状態、エラー状態、データ操作関数を含むオブジェクト
 */
export const useReflectionData = (goalId = null, projectId = null, targetUserId = null) => {
  const [userId, setUserId] = useState(null);
  const [reflections, setReflections] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) setUserId(user.id);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId || targetUserId) {
      fetchReflections();
      fetchSkills();
    }
  }, [userId, targetUserId, goalId]);

  /**
   * 振り返りデータを取得する
   * @async
   * @function fetchReflections
   */
  const fetchReflections = async () => {
    setLoading(true);
    setError(null);
    try {
      const effectiveUserId = targetUserId || userId;
      const data = await reflectionService.fetchReflections(effectiveUserId, goalId, projectId);
      setReflections(data);
    } catch (err) {
      console.error('振り返りデータの取得に失敗しました:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * スキルデータを取得する
   * @async
   * @function fetchSkills
   */
  const fetchSkills = async () => { 
    try {
      const effectiveUserId = targetUserId || userId;
      const data = await skillService.fetchSkills(effectiveUserId);
      setSkills(data);
    } catch (err) {
      console.error('スキルデータの取得に失敗しました:', err);
      setError(err.message);
    }
  };

  /**
   * 振り返りを編集する
   * @async
   * @function handleEditReflection
   * @param {Object} updatedReflection - 更新された振り返りデータ
   */
  const handleEditReflection = async (updatedReflection) => {
    if (targetUserId) {
      console.warn('他のユーザーの振り返りは編集できません');
      return;
    }
    try {
      await reflectionService.updateReflection(updatedReflection.id, updatedReflection);
      await fetchReflections();
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * 振り返りを削除する
   * @async
   * @function handleDeleteReflection
   * @param {Object} reflectionToDelete - 削除する振り返りデータ
   */
  const handleDeleteReflection = async (reflectionToDelete) => {
    if (targetUserId) {
      console.warn('他のユーザーの振り返りは削除できません');
      return;
    }
    try {
      await reflectionService.deleteReflection(reflectionToDelete.id);
      await fetchReflections();
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
      setError(error.message);
    }
  };

  /**
   * 新しいスキルを追加する
   * @async
   * @function handleAddSkill
   * @param {string} skillName - スキル名
   * @returns {Promise<Object>} 追加されたスキル
   */
  const handleAddSkill = async (skillName) => {
    if (targetUserId) {
      console.warn('他のユーザーのスキルは追加できません');
      return;
    }
    try {
      const newSkill = await skillService.addSkill(skillName);
      setSkills(prevSkills => [...prevSkills, newSkill]);
      return newSkill;
    } catch (error) {
      console.error('新しいスキルの追加に失敗しました:', error);
      throw error;
    }
  };

  return {
    userId,
    reflections,
    skills,
    loading,
    error,
    fetchReflections,
    handleEditReflection,
    handleDeleteReflection,
    handleAddSkill,
  };
};
