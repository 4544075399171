import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  TextField,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Box,
  Chip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ja from 'date-fns/locale/ja';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FlagIcon from '@mui/icons-material/Flag';
import { format } from 'date-fns';

const TaskItem = ({ task, onToggle, onEdit, onDelete, canEdit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(task.content);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditingPriority, setIsEditingPriority] = useState(false);
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);
  const [taskDetails, setTaskDetails] = useState({
    priority: task.priority || 'medium',
    dueDate: task.due_date ? new Date(task.due_date) : null,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartEditing = () => {
    if (canEdit && !isEditing) {
      setIsEditing(true);
      setEditedContent(task.content);
    }
  };

  const handleSaveEdit = () => {
    if (editedContent.trim() !== task.content) {
      onEdit(task.id, editedContent.trim());
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditedContent(task.content);
    }
  };

  const handlePriorityChange = (newPriority) => {
    setTaskDetails({ ...taskDetails, priority: newPriority });
    onEdit(task.id, task.content, {
      priority: newPriority,
      due_date: taskDetails.dueDate,
    });
    setIsEditingPriority(false);
  };

  const handleDueDateChange = (newDate) => {
    setTaskDetails({ ...taskDetails, dueDate: newDate });
    onEdit(task.id, task.content, {
      priority: taskDetails.priority,
      due_date: newDate,
    });
  };

  const getPriorityColor = () => {
    switch (task.priority) {
      case 'high':
        return '#ffebee';
      case 'medium':
        return '#fff3e0';
      case 'low':
        return '#f1f8e9';
      default:
        return 'transparent';
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case 'high':
        return '高';
      case 'medium':
        return '中';
      case 'low':
        return '低';
      default:
        return '中';
    }
  };

  const isOverdue = task.due_date && new Date(task.due_date) < new Date();

  return (
    <ListItem
      sx={{
        backgroundColor: getPriorityColor(),
        borderRadius: 1,
        mb: 1,
        border: isOverdue ? '1px solid #f44336' : 'none',
        cursor: canEdit ? 'pointer' : 'default',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: 2,
      }}
    >
      <Box sx={{ 
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        mb: 1,
      }}>
        <Checkbox
          checked={task.is_completed}
          onChange={(e) => onToggle(task.id, e.target.checked, task.content)}
          sx={{ mr: 1, mt: -0.5 }}
          onClick={(e) => e.stopPropagation()}
        />
        {isEditing ? (
          <TextField
            fullWidth
            size="small"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            onKeyDown={handleKeyPress}
            autoFocus
            onBlur={handleSaveEdit}
          />
        ) : (
          <Box sx={{ flex: 1 }}>
            <ListItemText
              onClick={handleStartEditing}
              primary={task.content}
              sx={{
                cursor: canEdit ? 'pointer' : 'default',
                '&:hover': canEdit ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: 1,
                } : {},
              }}
            />
          </Box>
        )}
        {canEdit && !isEditing && (
          <IconButton 
            onClick={handleClick}
            sx={{ ml: 1, mt: -1 }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Box>

      {canEdit && !isEditing && (
        <Box sx={{ 
          display: 'flex', 
          gap: 1,
          pl: 5,
          flexDirection: isMobile ? 'column' : 'row',
        }}>
          {isEditingPriority ? (
            <FormControl size="small" sx={{ width: isMobile ? '100%' : 120 }}>
              <Select
                value={taskDetails.priority}
                onChange={(e) => handlePriorityChange(e.target.value)}
                onClose={() => setIsEditingPriority(false)}
                autoFocus
                variant="outlined"
              >
                <MenuItem value="high">高</MenuItem>
                <MenuItem value="medium">中</MenuItem>
                <MenuItem value="low">低</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Chip
              icon={<FlagIcon />}
              label={getPriorityText(task.priority)}
              size="small"
              onClick={() => setIsEditingPriority(true)}
              sx={{ 
                cursor: 'pointer',
                width: isMobile ? '100%' : 'auto',
              }}
            />
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            {isEditingDueDate ? (
              <DateTimePicker
                value={taskDetails.dueDate}
                onChange={handleDueDateChange}
                onClose={() => setIsEditingDueDate(false)}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: { 
                      width: isMobile ? '100%' : 200,
                    },
                  },
                }}
                autoFocus
              />
            ) : (
              <Chip
                icon={<AccessTimeIcon />}
                label={task.due_date ? format(new Date(task.due_date), 'yyyy/MM/dd HH:mm') : '期限なし'}
                size="small"
                onClick={() => setIsEditingDueDate(true)}
                sx={{ 
                  cursor: 'pointer',
                  width: isMobile ? '100%' : 'auto',
                }}
                color={isOverdue ? 'error' : 'default'}
              />
            )}
          </LocalizationProvider>
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { handleClose(); onDelete(task.id); }}>
          <DeleteIcon sx={{ mr: 1 }} />
          削除
        </MenuItem>
      </Menu>
    </ListItem>
  );
};

export default TaskItem;